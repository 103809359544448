<template>
  <div>
    <Table
      :actions="actions"
      :items="$store.getters['manualProfileReview/tableData']"
      :detail="$store.getters['manualProfileReview/detailData']"
      :fields="$store.getters['manualProfileReview/tableFields']"
      :loading="$store.state.manualProfileReview.loadingTable"
      :filter="$store.state.manualProfileReview.tableFilter"
      :sort="$store.state.manualProfileReview.tableSort"
      :sort-options="sortOptions"
      :pagination="$store.state.manualProfileReview.tablePagination"
      :keyword="$store.state.manualProfileReview.tableKeyword"
      search-placeholder="Search email, username"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @resetDetail="resetDetail"
      @paginate="paginate"
      @search="search"
    />
    <b-modal
      v-model="setFlagModalVisible"
      :title="`${isRemoveFlag ? 'Remove' : 'Add'} ${selectedFlag} Tag`"
      hide-footer
    >
      <p>{{ `${isRemoveFlag ? 'Remove' : 'Add'} an ${selectedFlag} tag to this user. Are you sure?` }}</p>
      <FormBuilder
        :fields="setFlagFields"
        :loading-submit="$store.state.manualProfileReview.loadingSubmit"
        :success-message="`${selectedFlag} tag has been ${isRemoveFlag ? 'removed' : 'added'}!`"
        submit-label="Yes"
        block
        @submit="setFlag"
        @cancel="setFlagModalVisible = false"
      />
    </b-modal>
    <b-modal
      v-model="setProfileModalVisible"
      :title="`${profileStatus} User`"
      hide-footer
    >
      <p>{{ `This user account will ${profileStatus}. Are you sure? ` }}</p>
      <FormBuilder
        :fields="setFlagFields"
        :loading-submit="$store.state.manualProfileReview.loadingSubmit"
        submit-label="Yes"
        block
        @submit="setProfileStatus"
        @cancel="setProfileModalVisible = false"
      />
    </b-modal>
    <b-modal
      v-model="setProfileSuspendModalVisible"
      :title="`${profileSuspended} User`"
      hide-footer
    >
      <p>{{ `This user account will ${profileSuspended}. Are you sure? ` }}</p>
      <FormBuilder
        :fields="setFlagFields"
        :loading-submit="$store.state.manualProfileReview.loadingSubmit"
        submit-label="Yes"
        block
        @submit="setProfileSuspend"
        @cancel="setProfileSuspendModalVisible = false"
      />
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import FormBuilder from '@/layouts/components/FormBuilder.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
    FormBuilder,
  },
  data() {
    return {
      selectedProfile: '',
      selectedFlag: '',
      setFlagModalVisible: false,
      setFlagFields: [
        {
          key: 'comment',
          label: 'Comment',
          type: 'textarea',
          minLength: 1,
        },
      ],
      isRemoveFlag: false,
      setProfileModalVisible: false,
      setProfileSuspendModalVisible: false,
      sortOptions: [
        {
          key: 'nsfwCheckDate', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'nsfwCheckDate', direction: 'asc', label: 'Oldest' },
      ],
      actions: [
        'view',
        {
          type: 'other',
          children: [
            {
              label: 'Suspend User',
              event: data => this.updateProfileSuspend(data),
              visibility: {
                callback: data => (data?.isSuspended === 'No' || data?.isSuspended === undefined) && this.access,
              },
              tooltipText: 'Suspend an account means to hide the profile from the public, but the account owner can still access it to make changes to the content.',
            },
            {
              label: 'Unsuspend User',
              event: data => this.updateProfileSuspend(data),
              visibility: {
                callback: data => data?.isSuspended === 'Suspended' && this.access,
              },
            },
            {
              label: 'Activated User',
              event: data => this.updateProfileActivate(data),
              visibility: {
                callback: data => !data?.active && this.access,
              },
            },
            {
              label: 'Deactivated User',
              event: data => this.updateProfileActivate(data),
              visibility: {
                callback: data => data?.active && this.access,
              },
              tooltipText: 'Deactivating an account means that the user cannot access it anymore, and it is taken offline from public view.',
            },
            {
              label: 'Flag as SFW',
              event: data => this.updateProfileFlag(data, 'SFW'),
              visibility: this.canFlag('SFW'),
            },
            {
              label: 'Remove SFW tags',
              event: data => this.updateProfileFlag(data, 'SFW', true),
              visibility: this.canRemoveFlag('SFW'),
            },
            {
              label: 'Flag as NSFW',
              event: data => this.updateProfileFlag(data, 'NSFW'),
              visibility: this.canFlag('NSFW'),
            },
            {
              label: 'Remove NSFW tags',
              event: data => this.updateProfileFlag(data, 'NSFW', true),
              visibility: this.canRemoveFlag('NSFW'),
            },
            {
              label: 'Flag as Porn',
              event: data => this.updateProfileFlag(data, 'PORN'),
              visibility: this.canFlag('PORN'),
            },
            {
              label: 'Remove Porn tags',
              event: data => this.updateProfileFlag(data, 'PORN', true),
              visibility: this.canRemoveFlag('PORN'),
            },
          ],
        },
      ],
    }
  },
  computed: {
    profileStatus() {
      return this.selectedProfile?.active ? 'Deactivated' : 'Activated'
    },
    profileSuspended() {
      return this.selectedProfile?.isSuspended === 'Suspended' ? 'Unsuspend' : 'Suspend'
    },
  },
  methods: {
    loadData() {
      return this.$store.dispatch('manualProfileReview/getTableData')
    },
    loadDetail(id) {
      return this.$store.dispatch('manualProfileReview/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('manualProfileReview/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('manualProfileReview/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('manualProfileReview/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('manualProfileReview/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('manualProfileReview/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('manualProfileReview/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('manualProfileReview/SET_TABLE_KEYWORD', val)
    },
    patchProfile(payload, type) {
      const message = {
        flag: `${this.selectedFlag} tag has been ${this.isRemoveFlag ? 'removed' : 'added'}!`,
        profileStat: 'User has been deactived',
        profileSuspend: 'User has been suspended',
      }

      this.$store.dispatch('manualProfileReview/setProfileFlag', { id: this.selectedProfile?.id, payload })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: message[type],
              variant: 'success',
            },
          })

          this.setFlagModalVisible = false
          this.setProfileModalVisible = false
          this.setProfileSuspendModalVisible = false
          this.loadData()
        })
    },
    access() {
      return this.canAccess('manage', 'ManualProfileReview')
    },
    canManage() {
      return { callback: () => this.access }
    },
    canFlag(type) {
      return { callback: data => !data?.tags.includes(type) && this.access }
    },
    canRemoveFlag(type) {
      return { callback: data => data?.tags.includes(type) && this.access }
    },
    updateProfileFlag(val, flagType, remove) {
      this.isRemoveFlag = !!remove
      this.selectedProfile = {
        id: val?.item.id,
        tags: val?.item.tags || [],
      }
      this.selectedFlag = flagType
      this.setFlagModalVisible = true
    },
    setFlag(data) {
      let updatedTags = []

      if (this.isRemoveFlag) {
        updatedTags = this.selectedProfile.tags.filter(tag => tag !== this.selectedFlag)
      } else {
        updatedTags = [this.selectedFlag]
      }

      const payload = {
        comment: data?.comment,
        tags: updatedTags,
      }

      this.patchProfile(payload, 'flag')
    },
    setProfileStatus(data) {
      const payload = {
        active: !this.selectedProfile?.active,
        comment: data?.comment,
      }
      this.patchProfile(payload, 'profileStat')
    },
    updateProfileSuspend(data) {
      this.selectedProfile = data?.item
      this.setProfileSuspendModalVisible = true
    },
    setProfileSuspend(data) {
      const isSuspended = this.selectedProfile?.isSuspended

      const payload = {
        isSuspended: isSuspended !== 'Suspended',
        comment: data?.comment,
        tags: this.selectedProfile.tags,
      }

      this.patchProfile(payload, 'profileSuspend')
    },
    updateProfileActivate(data) {
      this.selectedProfile = data?.item
      this.setProfileModalVisible = true
    },
  },
}
</script>
