<template>
  <div>
    <b-row>
      <b-col
        v-show="true"
        v-if="!loadingDetail && userData"
        xl="4"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Profile</b-card-title>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item :to="`${$route.path}/edit`">
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click="confirmResetPassword">
                Reset password
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canAccess('manage', 'Role')"
                @click="showChangeRole"
              >
                Change role
              </b-dropdown-item>
              <b-dropdown-item
                :href="`${baseUrl}/${userData.nickname}`"
                target="_blank"
              >
                Go to profile
              </b-dropdown-item>
              <b-dropdown-divider />
              <!-- NSFW -->
              <b-dropdown-item
                v-if="!userData.tags.includes('NSFW') && canAccess('manage', 'ManualProfileReview')"
                @click="updateProfileFlag(userData, 'NSFW')"
              >
                Add NSFW Tag
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userData.tags.includes('NSFW') && canAccess('manage', 'ManualProfileReview')"
                @click="updateProfileFlag(userData, 'NSFW', true)"
              >
                Remove NSFW Tag
              </b-dropdown-item>
              <!-- SFW -->
              <b-dropdown-item
                v-if="!userData.tags.includes('SFW') && canAccess('manage', 'ManualProfileReview')"
                @click="updateProfileFlag(userData, 'SFW')"
              >
                Add SFW Tag
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userData.tags.includes('SFW') && canAccess('manage', 'ManualProfileReview')"
                @click="updateProfileFlag(userData, 'SFW', true)"
              >
                Remove SFW Tag
              </b-dropdown-item>
              <!-- PORN -->
              <b-dropdown-item
                v-if="!userData.tags.includes('PORN') && canAccess('manage', 'ManualProfileReview')"
                @click="updateProfileFlag(userData, 'PORN')"
              >
                Add PORN Tag
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userData.tags.includes('PORN') && canAccess('manage', 'ManualProfileReview')"
                @click="updateProfileFlag(userData, 'PORN', true)"
              >
                Remove PORN Tag
              </b-dropdown-item>
              <!-- Suspend -->
              <b-dropdown-item
                v-if="!userData.isSuspended"
                @click="updateProfileSuspend(userData)"
              >
                Suspend Account
              </b-dropdown-item>
              <b-dropdown-item
                v-if="userData.isSuspended"
                @click="updateProfileSuspend(userData)"
              >
                Unsuspend Account
              </b-dropdown-item>
              <!-- Delete user -->
              <b-dropdown-item @click="confirmDelete">
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <!-- Body -->
          <b-card-body class="pb-1">
            <b-row class="mx-n75">
              <b-col
                class="px-75"
                xl="auto"
              >
                <!-- Avatar -->
                <b-avatar
                  :src="avatar"
                  :text="avatarText(userData.fullName)"
                  variant="secondary"
                  class="mb-1"
                  size="100px"
                  rounded
                />
              </b-col>
              <b-col class="px-75">
                <div class="mb-1">
                  <!-- Username -->
                  <h4 class="mb-0">
                    <TableField
                      class="my-n25"
                      :value="userData.nickname"
                    />
                  </h4>
                  <!-- Username -->
                  <div class="card-text">
                    <TableField
                      class="mt-n50 mb-n25"
                      type="email"
                      :value="userData.email"
                    />
                  </div>

                  <div class="d-flex flex-wrap mt-1">
                    <b-button
                      :to="`${$route.path}/edit`"
                      size="sm"
                      variant="primary"
                    >
                      Edit
                    </b-button>
                    <b-button
                      :href="`${baseUrl}/${userData.nickname}`"
                      target="_blank"
                      variant="outline-dark"
                      size="sm"
                      class="ml-50 d-flex align-items-center border-default"
                    >
                      <span>Go to profile</span>
                      <feather-icon
                        icon="ExternalLinkIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>

            <!-- Description -->
            <div v-if="userData.description">
              <div class="s-12 mb-25">
                Description
              </div>

              <p
                v-if="userData.description"
                class="mb-2"
              >
                "{{ userData.description }}"
              </p>
            </div>

            <!-- User Stats -->
            <b-form-row class="mt-50">
              <!-- Wallet (credit) -->
              <b-col
                v-if="walletCredit"
                cols="auto"
              >
                <b-link
                  :href="`/user-transaction/${walletCredit.id}`"
                  target="_blank"
                >
                  <div class="d-flex align-items-center mb-50">
                    <b-avatar
                      variant="light-primary"
                      rounded
                    >
                      <feather-icon
                        icon="PocketIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <small>Wallet
                        <feather-icon
                          icon="ExternalLinkIcon"
                          class="ml-25"
                        />
                      </small>
                      <h5 class="mb-0 mt-25 d-flex align-items-center">
                        <TableField
                          class="mt-n50 mb-n25"
                          type="gold"
                          :value="walletCredit.balance"
                          has-zero
                        />
                      </h5>
                    </div>
                  </div>
                </b-link>
              </b-col>

              <!-- Earnings -->
              <b-col
                v-if="walletEarning"
                cols="auto"
              >
                <b-link
                  :href="`/user-transaction/${walletEarning.id}`"
                  target="_blank"
                >
                  <div class="d-flex align-items-center mb-50">
                    <b-avatar
                      variant="light-success"
                      rounded
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <small>Earnings
                        <feather-icon
                          icon="ExternalLinkIcon"
                          class="ml-25"
                        />
                      </small>
                      <h5 class="mb-0 mt-25 d-flex align-items-center">
                        <TableField
                          class="mt-n50 mb-n25"
                          prefix="$"
                          suffix=" USD"
                          :value="walletEarning.balance"
                        />
                      </h5>
                    </div>
                  </div>
                </b-link>
              </b-col>
            </b-form-row>
          </b-card-body>

          <b-card-body class="border-top pt-50 pb-2 px-1">
            <!-- Other data -->
            <FormView
              :data="userData"
              :fields="fields"
              lazy
            />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        v-show="true"
        xl="4"
      >
        <!-- Contract Creator -->
        <b-card
          v-if="contractData && contractData.type"
          class="mb-2"
          no-body
        >
          <b-card-header>
            <b-card-title>Creator Contract</b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="mb-2">
              <h4 class="mb-2">
                <b-badge :variant="contractMap[contractStatus]">
                  {{ creatorTypeMap[contractStatus] }}
                </b-badge>
              </h4>
              <b-row
                v-if="contractData && contractData.end"
                class="py-1 border-bottom border-top"
              >
                <b-col cols="auto">
                  <h5 class="mb-0">
                    Contract Status
                  </h5>
                </b-col>
                <b-col>
                  <div>
                    <span
                      class="mr-25"
                      :class="{
                        'text-success': contractStatus === 'active',
                        'text-secondary': contractStatus === 'waiting',
                        'text-error': contractStatus === 'ended'
                      }"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="8"
                          cy="6.5"
                          r="5.5"
                        />
                      </svg>
                    </span>
                    <template v-if="contractStatus === 'ended'">
                      <span>Ended</span>
                    </template>
                    <template v-if="contractStatus === 'active'">
                      <span>Active</span>
                    </template>
                    <template v-if="contractStatus === 'waiting'">
                      <span>Awaiting activation</span>
                    </template>
                  </div>
                </b-col>
              </b-row>
              <b-row
                v-if="contractData.start && contractData.end"
                class="py-1 border-bottom"
              >
                <b-col>
                  <h5>Contract start at</h5>
                  <div class="font-medium-2">
                    <b-badge
                      pill
                      :variant="contractStatus === 'ended' ? 'light-primary' : 'light-success'"
                    >
                      <feather-icon icon="CalendarIcon" />
                      {{ contractData.start | datetime }}
                    </b-badge>
                  </div>
                </b-col>
                <b-col>
                  <h5>Contract end at</h5>
                  <div class="font-medium-2">
                    <b-badge
                      pill
                      :variant="contractStatus === 'ended' ? 'light-primary' : 'light-success'"
                    >
                      <feather-icon icon="CalendarIcon" />
                      {{ contractData.end | datetime }}
                    </b-badge>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-button
              v-if="canUpdateContract"
              variant="primary"
              block
              @click="updateContract"
            >
              <span v-if="!contractData.end">Add Contract</span>
              <span v-else>
                {{ contractStatus === 'ended' ? 'Add New' : 'Update' }} Contract
              </span>
            </b-button>
          </b-card-body>
        </b-card>

        <!-- Current plan -->
        <b-card
          v-if="!$store.state.user.loadingPlanStatus && planStatusData"
          class="mb-2"
          no-body
        >
          <b-card-header>
            <b-card-title>Current Plan</b-card-title>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item @click="changePlan">
                Change plan
              </b-dropdown-item>
              <b-dropdown-divider />
              <!-- Delete user -->
              <b-dropdown-item disabled>
                Cancel plan
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body>
            <h4 class="mb-2">
              <b-badge :variant="tierMap[userData.tier]">
                {{ userData.tier.replace(/_/g, ' ') }}
              </b-badge>
            </h4>

            <p
              v-if="['BOOST', 'BOOST_PLUS'].includes(userData.tier) && planStatusData.subscriptionEnd"
              class="text-muted mt-n1"
            >
              Until {{ planStatusData.subscriptionEnd | datetime }}
            </p>

            <ul class="list-unstyled">
              <li
                v-for="(item, index) in tierBenefits[planStatusData.tier]"
                :key="`tier-benefit-${index}`"
                class="mb-25"
              >
                <span class="align-middle">{{ item }}</span>
              </li>
              <li>
                <a
                  :href="`${baseUrl}/boost`"
                  target="_blank"
                >Show more..</a>
              </li>
            </ul>
            <b-button
              variant="primary"
              block
              @click="changePlan"
            >
              Change Plan
            </b-button>
          </b-card-body>
        </b-card>

        <!-- Stream Alert -->
        <b-card
          v-if="!$store.state.user.loadingStreamAlert"
          class="mb-2"
          no-body
        >
          <b-card-header>
            <b-card-title class="d-flex align-items-center justify-content-between">
              <feather-icon
                icon="PlayCircleIcon"
                size="18"
                class="mr-50"
              />
              Stream Settings
            </b-card-title>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                v-show="false"
                disabled
                @click="changePlan"
              >
                Show queue
              </b-dropdown-item>
              <b-dropdown-item @click="requestReloadSession">
                Reload session
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-card-body class="pt-0">
            <!-- Stream alert -->
            <div class="py-75">
              <b-form-row
                v-if="!$store.state.user.loadingStreamAlert"
                class="align-items-center"
              >
                <b-col cols="auto">
                  <h5 class="mb-0">
                    Stream Alert
                  </h5>
                </b-col>
                <b-col>
                  <div v-if="streamAlertData">
                    <span
                      class="mr-25"
                      :class="{
                        'text-success': streamAlertData && streamAlertData.isAlertEnabled,
                        'text-secondary': !streamAlertData || !streamAlertData.isAlertEnabled
                      }"
                    ><svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8"
                        cy="6.5"
                        r="5.5"
                      />
                    </svg></span>
                    <span v-if="streamAlertData && streamAlertData.isAlertEnabled">Active</span>
                    <span v-else>Inactive</span>
                  </div>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col cols="auto">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    class="border-default mt-50"
                    :disabled="!streamAlertData"
                    @click="streamAlertModalVisible = true"
                  >
                    Settings
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    class="border-default mt-50"
                    :disabled="!streamAlertData"
                    @click="testAlert('MAIN')"
                  >
                    <feather-icon
                      icon="BellIcon"
                      class="mr-25"
                    />
                    Test Alert
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    class="border-default mt-50"
                    :disabled="!streamAlertData"
                    @click="openStreamAlert"
                  >
                    Open widget
                    <feather-icon
                      icon="ExternalLinkIcon"
                      class="ml-25"
                    />
                  </b-button>
                </b-col>
              </b-form-row>
            </div>

            <!-- Donation Overlay -->
            <div class="py-75">
              <b-form-row
                v-if="!$store.state.user.loadingDonationOverlay"
                class="align-items-center"
              >
                <b-col cols="auto">
                  <h5 class="mb-0">
                    Donation Overlay
                  </h5>
                </b-col>
                <b-col>
                  <div v-if="donationOverlayData">
                    <span
                      class="mr-25"
                      :class="{
                        'text-success': donationOverlayData && donationOverlayData.isOverlayEnabled,
                        'text-secondary': !donationOverlayData || !donationOverlayData.isOverlayEnabled
                      }"
                    ><svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8"
                        cy="6.5"
                        r="5.5"
                      />
                    </svg></span>
                    <span v-if="donationOverlayData && donationOverlayData.isOverlayEnabled">Active</span>
                    <span v-else>Inactive</span>
                  </div>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col cols="auto">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    class="border-default mt-50"
                    :disabled="!donationOverlayData"
                    @click="donationOverlayModalVisible = true"
                  >
                    Settings
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    class="border-default mt-50"
                    :disabled="!donationOverlayData"
                    @click="openDonationOverlay"
                  >
                    Open widget
                    <feather-icon
                      icon="ExternalLinkIcon"
                      class="ml-25"
                    />
                  </b-button>
                </b-col>
              </b-form-row>
            </div>

            <!-- Media share -->
            <div class="py-75">
              <b-form-row
                v-if="!$store.state.user.loadingMediaShare"
                class="align-items-center"
              >
                <b-col cols="auto">
                  <h5 class="mb-0">
                    Media Share
                  </h5>
                </b-col>
                <b-col>
                  <div v-if="mediaShareData">
                    <span
                      class="mr-25"
                      :class="{
                        'text-success': mediaShareData && mediaShareData.isEnabled,
                        'text-secondary': !mediaShareData || !mediaShareData.isEnabled
                      }"
                    ><svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8"
                        cy="6.5"
                        r="5.5"
                      />
                    </svg></span>
                    <span v-if="mediaShareData && mediaShareData.isEnabled">Active</span>
                    <span v-else>Inactive</span>
                  </div>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col cols="auto">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    class="border-default mt-50"
                    :disabled="!mediaShareData"
                    @click="mediaShareModalVisible = true"
                  >
                    Settings
                  </b-button>
                </b-col>
                <b-col cols="auto">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    class="border-default mt-50"
                    :disabled="!mediaShareData"
                    @click="openMediaShare"
                  >
                    Open widget
                    <feather-icon
                      icon="ExternalLinkIcon"
                      class="ml-25"
                    />
                  </b-button>
                </b-col>
              </b-form-row>
            </div>
          </b-card-body>
        </b-card>

        <!-- Withdrawal Details -->
        <b-card
          v-if="canAccess('manage', 'Withdrawal') && withdrawalDetails && withdrawalDetails.verificationPictureURL"
          class="mb-2"
          no-body
        >
          <b-card-header>
            <b-card-title class="d-flex align-items-center justify-content-between">
              <feather-icon
                icon="PlayCircleIcon"
                size="18"
                class="mr-50"
              />
              Withdrawal account
            </b-card-title>

            <b-dropdown
              v-if="withdrawalDetails && withdrawalDetails.verificationPictureURL && !withdrawalDetails.verified"
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                @click="verifiedIdModalVisible = true"
              >
                Verify ID
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-card-body class="pt-0">
            <!-- Stream alert -->
            <div class="py-75">
              <b-form-row
                class="align-items-center"
              >
                <b-col cols="auto">
                  <h5 class="mb-0">
                    ID Card
                  </h5>
                </b-col>
                <b-col>
                  <div v-if="withdrawalDetails">
                    <span
                      class="mr-25"
                      :class="{
                        'text-success': withdrawalDetails && withdrawalDetails.verificationPictureURL && withdrawalDetails.verified,
                        'text-secondary': !withdrawalDetails,
                      }"
                    ><svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="8"
                        cy="6.5"
                        r="5.5"
                      />
                    </svg></span>
                    <span v-if="withdrawalDetails && withdrawalDetails.verificationPictureURL && withdrawalDetails.verified">Verified</span>
                    <span v-else>Unverified</span>
                  </div>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col cols="auto">
                  <b-button
                    variant="outline-dark"
                    size="sm"
                    class="border-default mt-50"
                    :disabled="withdrawalDetails && !withdrawalDetails.verificationPictureURL"
                    @click="openIdCard"
                  >
                    Open picture
                    <feather-icon
                      icon="ExternalLinkIcon"
                      class="ml-25"
                    />
                  </b-button>
                </b-col>
              </b-form-row>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        v-show="true"
        xl="4"
      >
        <!-- Social media -->
        <b-card
          v-if="!loadingDetail && userData"
          no-body
        >
          <b-card-header>
            <b-card-title class="d-flex align-items-center">
              <feather-icon
                icon="Link2Icon"
                size="18"
                class="mr-50"
              />
              Social media
            </b-card-title>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item disabled>
                Edit
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-card-body v-if="socialMedia.length">
            <b-row
              align-h="center"
              no-gutters
            >
              <b-col
                v-for="(item, index) in socialMedia"
                :key="`social-${index}`"
                cols="4"
                xl="3"
                class="mb-1 mt-1"
              >
                <a
                  :href="item.url"
                  target="_blank"
                  class="text-unset"
                >
                  <div class="text-center mb-50">
                    <b-img
                      :src="item.icon"
                      width="36px"
                      height="36px"
                    />
                  </div>
                  <div class="text-center s-12">
                    {{ item.name }}
                    <feather-icon icon="ExternalLinkIcon" />
                  </div>
                </a>
              </b-col>
            </b-row>
          </b-card-body>
          <b-list-group
            v-else
            flush
          >
            <b-list-group-item
              disabled
            >
              No data available
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <!-- Languages -->
        <b-card
          v-if="!loadingDetail && userData"
          no-body
        >
          <b-card-header>
            <b-card-title class="d-flex align-items-center">
              <feather-icon
                icon="GlobeIcon"
                size="18"
                class="mr-50"
              />
              Languages
            </b-card-title>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item disabled>
                Edit
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-list-group flush>
            <b-list-group-item
              v-for="(item) in userLanguageData"
              :key="`language-${item.id}`"
            >
              <TableField
                class="my-n25"
                :value="item.name"
              />
            </b-list-group-item>
            <b-list-group-item
              v-if="!userLanguageData.length"
              disabled
            >
              No data available
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <!-- Achievement -->
        <b-card
          v-if="!loadingAchievement && achievementData"
          no-body
        >
          <b-card-header>
            <b-card-title class="d-flex align-items-center">
              <feather-icon
                icon="AwardIcon"
                size="18"
                class="mr-50"
              />
              Achievements
            </b-card-title>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item disabled>
                Edit
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-list-group flush>
            <b-list-group-item
              v-for="(item) in achievementData"
              :key="`achievement-${item.id}`"
            >
              <TableField
                class="my-n25"
                :value="item.event"
              />
            </b-list-group-item>
            <b-list-group-item
              v-if="!achievementData.length"
              disabled
            >
              No data available
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <!-- Customization  -->
        <b-card
          v-if="!loadingDetail && userData && userData.profile"
          no-body
        >
          <b-card-header>
            <b-card-title class="d-flex align-items-center">
              <feather-icon
                icon="AwardIcon"
                size="18"
                class="mr-50"
              />
              Customization
            </b-card-title>

            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item @click="changeCustomization">
                Edit
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-list-group flush>
            <h4 class="ml-2 font-weight-bold menu-title">
              Badges :
            </h4>
            <ul
              v-if="
                userData.profile &&
                  userData.profile.customization &&
                  userData.profile.customization.badges.length !== 0
              "
              class="list-group-item"
            >
              <li
                v-for="(item, index) in userData.profile.customization.badges"
                :key="`badges-${index}`"
                class="ml-2"
              >
                {{ item }}
              </li>
            </ul>
            <div
              v-else
              class="ml-2 mt-1"
              disabled
            >
              No badges available
            </div>
          </b-list-group>

          <b-list-group flush>
            <h4 class="ml-2 mt-1 font-weight-bold menu-title">
              Borders :
            </h4>
            <div class="ml-2 mt-1">
              Options :
            </div>
            <div>
              <ul
                v-if="
                  userData.profile &&
                    userData.profile.customization &&
                    userData.profile.customization.border &&
                    userData.profile.customization.border.options &&
                    userData.profile.customization.border.options.length !== 0
                "
                class="list-group-item"
              >
                <li
                  v-for="(item, index) in userData.profile.customization.border
                    .options"
                  :key="`borders-${index}`"
                  class="ml-2"
                >
                  {{ item }}
                </li>
              </ul>
              <div
                v-else
                class="ml-2 mt-1"
                disabled
              >
                No Border available
              </div>
            </div>
            <div class="ml-2 mt-1">
              Selected :
            </div>
            <div>
              <div
                v-if="userData.profile &&
                  userData.profile.customization &&
                  userData.profile.customization.border &&
                  userData.profile.customization.border.selected
                "
                class="ml-2 mt-1"
              >
                {{
                  userData.profile.customization.border
                    .selected
                }}
              </div>
              <div
                v-else
                class="ml-2 mt-1"
              >
                No selected border yet
              </div>
            </div>
          </b-list-group>

          <b-list-group
            class="mb-1"
            flush
          >
            <h4 class="ml-2 mt-1 font-weight-bold menu-title">
              Emojis :
            </h4>
            <ul
              v-if="
                userData.profile &&
                  userData.profile.customization &&
                  userData.profile.customization.emojis &&
                  userData.profile.customization.emojis.length !== 0
              "
              class="list-group-item"
            >
              <li
                v-for="(item, index) in userData.profile.customization.emojis"
                :key="`emojis-${index}`"
                class="ml-2"
              >
                {{ item }}
              </li>
            </ul>
            <div
              v-else
              class="ml-2 mt-1"
              disabled
            >
              No emojis available
            </div>
          </b-list-group>
        </b-card>
      </b-col>

      <!-- Listing -->
      <b-col
        xl="6"
      >
        <b-card
          v-if="!loadingService || serviceData.length"
          no-body
        >
          <b-card-header>
            <b-card-title class="d-flex align-items-center">
              <feather-icon
                icon="CoffeeIcon"
                size="18"
                class="mr-50"
              />
              Listing
            </b-card-title>
          </b-card-header>

          <b-list-group>
            <b-list-group-item
              v-for="(item) in serviceData"
              :key="`service-${item.id}`"
              class="inactive"
            >
              <b-row>
                <!-- Image -->
                <b-col cols="auto">
                  <a
                    v-if="item.media && item.media.length > 0 && item.media[0].thumbUrl"
                    :href="item.media[0].thumbUrl"
                    target="_blank"
                  >
                    <b-img
                      :src="$options.filters.thumbnail(item.media[0].thumbUrl, 64)"
                      width="64px"
                      height="64px"
                      class="img-cover"
                      rounded
                    />
                  </a>
                  <a
                    v-else-if="item.game && item.game.image"
                    :href="item.game.image"
                    target="_blank"
                  >
                    <b-img
                      :src="$options.filters.thumbnail(item.game.image, 80)"
                      width="80px"
                      height="80px"
                      class="img-cover"
                      rounded
                    />
                  </a>
                </b-col>
                <b-col>
                  <h4>
                    <b-badge
                      v-if="!item.isActive"
                      class="mr-50"
                    >
                      Inactive
                    </b-badge>
                    <router-link :to="`/service/${item.id}`">
                      {{ item.name }}
                    </router-link>

                  </h4>
                  <p v-if="item.description">
                    "{{ _.truncate(item.description, { length: 100 }) }}"
                  </p>

                  <div class="mb-50 ml-n25">
                    <b-badge
                      v-if="item.catalog"
                      variant="light-primary"
                      class="mr-25 text-capitalize"
                    >
                      {{ catalogMap[item.catalog] }}
                    </b-badge>
                    <b-badge
                      v-if="item.catalog === 'service'"
                      variant="light-primary"
                      class="mr-25 text-capitalize"
                    >
                      {{ _.get(item, 'category.name') }}
                    </b-badge>
                  </div>
                </b-col>
                <b-col cols="auto">
                  <h4>
                    <b-row
                      v-if="item.servicePrices && item.servicePrices.length && item.servicePrices[0].rate !== 0"
                      no-gutters
                      align-v="center"
                    >
                      <b-col cols="auto">
                        <b-img
                          :src="goldIcon"
                          width="14px"
                          class="mr-25"
                        />
                      </b-col>
                      <b-col>
                        {{ item.servicePrices[0].rate }} <span
                          v-if="item.servicePrices && item.servicePrices.length && item.servicePrices[0].hour"
                        >/ {{ item.servicePrices[0].hour }} hour(s)</span>
                      </b-col>
                    </b-row>
                    <span
                      v-else-if="item.servicePrices && item.servicePrices.length"
                      class="font-weight-bold"
                    >FREE</span>
                  </h4>
                </b-col>
                <b-col cols="auto">
                  <b-dropdown
                    variant="link"
                    no-caret
                    class="chart-dropdown"
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                        class="text-body cursor-pointer"
                      />
                    </template>
                    <b-dropdown-item :to="`/service/${item.id}/edit`">
                      Edit
                    </b-dropdown-item>
                    <b-dropdown-item @click="selectListing(item)">
                      {{ item.isActive ? 'Deactivate' : 'Activate' }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item
              v-if="!serviceData.length"
              disabled
            >
              No data available
            </b-list-group-item>
          </b-list-group>

          <div
            v-if="$store.state.user.serviceHasNextPage"
            class="py-2"
          >
            <b-button
              variant="primary"
              :disabled="loadingService"
              class="d-flex align-items-center mx-auto"
              @click="loadNextServiceData"
            >
              <b-spinner
                v-if="loadingService"
                class="mr-50"
                small
              />
              Load more
            </b-button>
          </div>
          <div
            v-else-if="serviceData.length"
            class="py-1 text-center text-muted"
          >
            Showing all data
          </div>
        </b-card>
      </b-col>

      <!-- Transaction -->
      <b-col
        xl="6"
      >
        <b-card
          v-if="!loadingTransaction || transactionData.length"
          no-body
        >
          <b-card-header>
            <b-row class="w-fill-available">
              <b-col>
                <b-card-title class="d-flex align-items-center">
                  <feather-icon
                    icon="ListIcon"
                    size="18"
                    class="mr-50"
                  />
                  Transaction
                </b-card-title>
              </b-col>
              <b-col
                cols="auto"
              >
                <b-link
                  :disabled="!transactionData.length"
                  @click="showAllTransaction"
                >
                  Show all
                </b-link>
              </b-col>
            </b-row>
          </b-card-header>

          <b-list-group flush>
            <b-list-group-item
              v-for="(item) in transactionData"
              :key="`service-${item.human_id}`"
            >
              <TransactionItem
                :title="item.ui_title"
                :description="item.ui_description"
                :type="item.transfer_type"
                :usecase="item.usecase"
                :price="item.price_as_charged_display"
                :currency="item.currency"
                :status="item.payment_status"
                :created-at="item.created_at"
                :supporter-username="_.get(item, 'usecase_data.data.donator_user_nickname', undefined)"
                :supporter-id="_.get(item, 'usecase_data.data.donator_user_id', undefined)"
              />
            </b-list-group-item>
            <b-list-group-item
              v-if="!transactionData.length"
              disabled
            >
              No data available
            </b-list-group-item>
          </b-list-group>

          <div
            v-if="$store.state.user.transactionHasNextPage"
            class="py-2"
          >
            <b-button
              variant="primary"
              :disabled="loadingTransaction"
              class="d-flex align-items-center mx-auto"
              @click="loadNextTransactionData"
            >
              <b-spinner
                v-if="loadingTransaction"
                class="mr-50"
                small
              />
              Load more
            </b-button>
          </div>
          <div
            v-else-if="transactionData.length"
            class="py-1 text-center text-muted"
          >
            Showing all data
          </div>

        </b-card>
      </b-col>
    </b-row>

    <!-- Reset Password confirmation modal -->
    <b-modal
      v-model="resetPasswordModalVisible"
      title="Reset Password"
      ok-title="Yes, reset"
      cancel-variant="white"
      @ok="resetPassword"
    >
      A URL to reset the password will be sent to their email. Are you sure?.
    </b-modal>

    <!-- Role selection modal -->
    <b-modal
      :visible="changeRoleModalVisible"
      title="Change role"
      ok-title="Save role"
      no-close-on-backdrop
      :ok-disabled="loadingSubmit"
      @ok.prevent="confirmRole"
      @hidden="changeRoleModalVisible = false"
    >
      <label for="user-role">Roles</label>
      <v-select
        id="user-role"
        v-model="selectedRoles"
        multiple
        label="text"
        :options="roleOptions"
      />
    </b-modal>

    <!-- Change role confirmation modal -->
    <b-modal
      v-model="roleConfirmationModalVisible"
      title="Confirm role"
      ok-title="Yes, save"
      cancel-variant="white"
      @ok="saveRole"
    >
      The selected role(s) will be applied immediately. Are you sure?.
    </b-modal>

    <!-- Delete User confirmation modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete User"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deleteUser"
    >
      Are you sure want to delete this user?. This action cannot be undone.
    </b-modal>

    <!-- Add NSFW, SFW, PORN Tag User confirmation modal -->
    <b-modal
      v-model="setFlagModalVisible"
      :title="`${isRemoveFlag ? 'Remove' : 'Add'} ${selectedFlag} Tag`"
      hide-footer
    >
      <p>{{ `${isRemoveFlag ? 'Remove' : 'Add'} an ${selectedFlag} tag to this user. Are you sure?` }}</p>
      <FormBuilder
        :fields="setFlagFields"
        :loading-submit="$store.state.manualProfileReview.loadingSubmit"
        :success-message="`${selectedFlag} tag has been ${isRemoveFlag ? 'removed' : 'added'}!`"
        submit-label="Yes"
        block
        @submit="setFlag"
        @cancel="setFlagModalVisible = false"
      />
    </b-modal>

    <!-- Suspend User confirmation modal -->
    <b-modal
      v-model="suspendUserModalVisible"
      :title="`${suspendUserModalTitle} User`"
      ok-title="Yes"
      cancel-variant="white"
      @ok="suspendUser"
    >
      {{ `Are you sure want ${suspendUserModalTitle} to this user?` }}
    </b-modal>

    <!-- Change plan modal -->
    <b-modal
      v-model="changePlanModalVisible"
      title="Change plan"
      size="lg"
      hide-footer
    >
      <FormBuilder
        :fields="changePlanFields"
        :loading-submit="$store.state.user.loadingSubmit"
        :data="planStatusData"
        :loading-detail="$store.state.user.loadingPlanStatus"
        success-message="'The withdrawal has been rejected'"
        submit-label="Submit"
        :diff-only="false"
        return-only
        update
        block
        @submit="submitChangePlan"
        @cancel="changePlanModalVisible = false"
      />
    </b-modal>

    <!-- Stream alert modal -->
    <b-modal
      v-model="streamAlertModalVisible"
      title="Stream Alert Settings"
      size="md"
      hide-footer
      body-class="pt-0 px-0 pb-2 overflow-hidden rounded"
    >
      <div
        v-if="streamAlertData"
      >
        <b-tabs>
          <b-tab title="Settings">
            <StreamAlertSettings
              class="px-1"
              :data="streamAlertData"
            />
          </b-tab>
          <b-tab title="Text-to-Speech">
            <TextToSpeechSettings
              class="px-1"
              :data="streamAlertData"
            />
          </b-tab>
          <b-tab title="Provanity filter">
            <ProfanityFilterSettings
              class="px-1"
              :data="streamAlertData.userProfanityFilter"
            />
          </b-tab>
          <b-tab title="Variations">
            <div
              v-if="!streamAlertData.variations || !streamAlertData.variations.length"
              class="pb-5 pt-2 text-muted d-flex align-items-center justify-content-center"
            >No variations available
            </div>
            <app-collapse
              accordion
              class="mt-n50"
            >
              <app-collapse-item
                v-for="(item, index) in streamAlertData.variations"
                :key="`variant-${index}`"
                :title="`'${item.name}' Variant`"
                title-class="pl-25"
              >
                <StreamAlertSettings
                  :data="item"
                />
              </app-collapse-item>
            </app-collapse>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>

    <!-- Donation overlay modal -->
    <b-modal
      v-model="donationOverlayModalVisible"
      title="Donation Overlay Settings"
      size="md"
      hide-footer
      body-class="pt-0 px-0 pb-2 overflow-hidden rounded"
    >
      <div
        v-if="donationOverlayData"
      >
        <DonationOverlaySettings
          class="px-1"
          :data="donationOverlayData"
        />
      </div>
    </b-modal>

    <!-- Media share modal -->
    <b-modal
      v-model="mediaShareModalVisible"
      title="Media Share Settings"
      size="md"
      hide-footer
      body-class="pt-0 px-0 pb-2 overflow-hidden rounded"
    >
      <div
        v-if="mediaShareData"
      >
        <MediaShareSettings
          class="px-1"
          :data="mediaShareData"
        />
      </div>
    </b-modal>

    <!-- Change customization modal -->
    <b-modal
      v-model="changeCustomizationModalVisible"
      title="Change customization"
      ok-title="Save customization"
      no-close-on-backdrop
      :ok-disabled="loadingSubmit"
      @ok="saveCustomization"
    >
      <label for="badges">Badges</label>
      <v-select
        id="badges"
        v-model="selectedBadges"
        multiple
        label="text"
        :options="badgesOptions"
      />
      <label for="border-options">Border Options</label>
      <v-select
        id="border-options"
        v-model="selectedBorderOptions"
        multiple
        label="text"
        :options="bordersOptions"
      />
      <label for="badges">Selected Border</label>
      <v-select
        id="badges"
        v-model="selectedBorder"
        label="text"
        :options="bordersOptions"
      />
      <label for="emojis">Emojis</label>
      <v-select
        id="emojis"
        v-model="selectedEmojis"
        multiple
        label="text"
        :options="emojisOptions"
      />
    </b-modal>

    <!-- Inactive Listing confirmation modal -->
    <b-modal
      v-model="inactiveListingModalVisible"
      title="Deactivate Listing"
      ok-title="Yes, sure"
      cancel-variant="white"
      @ok="updateListingStatus(selectedListing)"
    >
      Are you sure want to deactivate this listing?.
    </b-modal>

    <!-- Reload session confirmation modal -->
    <b-modal
      v-model="reloadSessionModalVisible"
      title="Reload session"
      ok-title="Yes, sure"
      cancel-variant="white"
      :ok-disabled="loadingReloadSession"
      @ok.prevent="reloadSession"
    >
      Please ensure there is no stream alert or media share playing currently. Are you sure?
    </b-modal>

    <!-- Approve ID Card verification modal -->
    <b-modal
      v-model="verifiedIdModalVisible"
      title="Approve"
      ok-title="Yes, approve"
      cancel-variant="danger"
      cancel-title="Reject"
      :ok-disabled="$store.state.user.loadingSubmit"
      @ok.prevent="verify(true)"
      @cancel="rejectIdModalVisible = true"
    >
      Are you sure you want to approve this account's verification ID?
    </b-modal>

    <!-- Reject ID Card verification modal -->
    <b-modal
      v-model="rejectIdModalVisible"
      title="Reject"
      ok-title="Yes, reject"
      cancel-title="Cancel"
      :ok-disabled="$store.state.user.loadingSubmit"
      @ok.prevent="verify(false, rejectReason)"
    >
      Are you sure you want to reject this account's verification ID?
      <div class="py-1">
        <input
          v-model="rejectReason"
          placeholder="Write your reason here"
          style="width: 100%"
        >
      </div>

    </b-modal>
    <b-modal
      v-model="updateContractVisible"
      title="Update Contract"
      size="lg"
      hide-footer
    >
      <FormBuilder
        :fields="updateContractFields"
        :loading-submit="$store.state.user.loadingSubmit"
        :data="contractData"
        success-message="'The withdrawal has been rejected'"
        submit-label="Save"
        :diff-only="false"
        return-only
        update
        block
        @submit="submitContract"
        @cancel="updateContractVisible = false"
      />
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import TableField from '@/layouts/components/TableField.vue'
import FormBuilder from '@/layouts/components/FormBuilder.vue'
import FormView from '@/layouts/components/FormView.vue'
import MediaShareSettings from '@/layouts/components/MediaShareSettings.vue'
import StreamAlertSettings from '@/layouts/components/StreamAlertSettings.vue'
import DonationOverlaySettings from '@/layouts/components/DonationOverlaySettings.vue'
import TextToSpeechSettings from '@/layouts/components/TextToSpeechSettings.vue'
import ProfanityFilterSettings from '@/layouts/components/ProfanityFilterSettings.vue'
import TransactionItem from '@/layouts/components/TransactionItem.vue'
import goldIcon from '@/assets/images/icons/gank-gold.svg'
import facebookIcon from '@/assets/images/icons/facebook.svg'
import twitchIcon from '@/assets/images/icons/twitch.svg'
import twitterIcon from '@/assets/images/icons/twitter.svg'
import youtubeIcon from '@/assets/images/icons/youtube.svg'
import instagramIcon from '@/assets/images/icons/instagram.svg'
import tiktokIcon from '@/assets/images/icons/tiktok.svg'
import discordIcon from '@/assets/images/icons/discord.svg'
import steamIcon from '@/assets/images/icons/steam.svg'
import otherIcon from '@/assets/images/icons/internet.png'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

import {
  BImg,
  BCard,
  BFormRow,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BBadge,
  BButton,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BListGroup,
  BListGroupItem,
  BLink,
  BSpinner,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { mapGetters, mapState } from 'vuex'
import countries from '@/list/countries'
import moment from 'moment'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  metaInfo() {
    return {
      title: this.userData && this.userData.nickname ? this.userData.nickname : undefined,
      titleTemplate: this.userData && this.userData.nickname ? 'User - %s' : 'Admin - %s',
    }
  },
  filters: {
    timeAgo: value => {
      if (!value) return ''
      return moment(value).fromNow()
    },
  },
  components: {
    BImg,
    TableField,
    TransactionItem,
    FormView,
    BCard,
    BFormRow,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
    BButton,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BListGroup,
    BListGroupItem,
    BLink,
    BSpinner,
    FormBuilder,
    vSelect,
    MediaShareSettings,
    DonationOverlaySettings,
    AppCollapse,
    AppCollapseItem,
    TextToSpeechSettings,
    ProfanityFilterSettings,
    BTabs,
    BTab,
    StreamAlertSettings,
  },
  data() {
    return {
      goldIcon,
      resetPasswordModalVisible: false,
      changeRoleModalVisible: false,
      roleConfirmationModalVisible: false,
      deleteModalVisible: false,
      unNSFWModalVisible: false,
      changePlanModalVisible: false,
      streamAlertModalVisible: false,
      mediaShareModalVisible: false,
      donationOverlayModalVisible: false,
      changeCustomizationModalVisible: false,
      inactiveListingModalVisible: false,
      reloadSessionModalVisible: false,
      loadingReloadSession: false,
      updateContractVisible: false,
      baseUrl: process.env.VUE_APP_CLIENT_URL,
      verifiedIdModalVisible: false,
      rejectIdModalVisible: false,
      rejectReason: '',
      roleOptions: [
        {
          value: 'customer',
          text: 'Customer',
        },
        {
          value: 'super-admin',
          text: 'Super admin',
        },
        {
          value: 'administrator',
          text: 'Admin',
        },
        {
          value: 'finance',
          text: 'Finance',
        },
        {
          value: 'marketer',
          text: 'Marketer',
        },
        {
          value: 'moderator',
          text: 'Moderator',
        },
        {
          value: 'customer-support',
          text: 'Customer support',
        },
        {
          value: 'beta-tester',
          text: 'Beta tester',
        },
      ],
      selectedRoles: [],

      badgesOptions: [
        {
          value: 'tick',
          text: 'tick',
        },
        {
          value: 'verified',
          text: 'verified',
        },
        {
          value: 'gambit',
          text: 'gambit',
        },
      ],
      selectedBadges: [],

      bordersOptions: [
        {
          value: '',
          text: 'None',
        },
        {
          value: 'bronze',
          text: 'Bronze',
        },
        {
          value: 'silver',
          text: 'Silver',
        },
        {
          value: 'gold',
          text: 'Gold',
        },
        {
          value: 'platinum',
          text: 'Platinum',
        },
        {
          value: 'emerald',
          text: 'Emerald',
        },
        {
          value: 'ruby',
          text: 'Ruby',
        },
        {
          value: 'sapphire',
          text: 'Sapphire',
        },
      ],
      selectedBorderOptions: [],
      selectedBorder: {},

      emojisOptions: [
        {
          value: 'kickstarter',
          text: 'Kickstarter',
        },
      ],
      selectedEmojis: [],

      tierMap: {
        BOOST_PLUS: 'primary',
        BOOST: 'warning',
        FREE: 'success',
      },
      contractMap: {
        organic: 'secondary',
        active: 'success',
        ended: 'danger',
        waiting: 'success',
      },
      creatorTypeMap: {
        organic: 'ORGANIC',
        active: 'CONTRACTED',
        ended: 'EX CONTRACTED',
        waiting: 'CONTRACTED',
      },
      catalogMap: {
        service: 'Service',
        product: 'Digital goods',
        physical: 'Merchandise',
      },

      tierBenefits: {
        BOOST_PLUS: [
          '50 GB storage',
          '10 Membership tiers',
          'Unlimited listings',
        ],
        BOOST: [
          '20 GB storage',
          '3 Membership tiers',
          'Sell 50 more products',
        ],
        FREE: [
          '2 GB storage',
          '1 Membership tiers',
          'Sell 10 more products',
        ],
      },

      roleMap: {
        administrator: 'primary',
        customer: 'secondary',
      },
      verifiedStatusMap: {
        verified: 'success',
        unverified: 'danger',
      },
      activeStatusMap: {
        active: 'success',
        inactive: 'danger',
      },

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'fullName',
          label: 'Name',
        },
        {
          key: 'profile.countryCode',
          label: 'Country',
          type: 'country',
          filter: {
            key: 'countryCode',
            type: 'select',
            options: countries,
          },
        },
        {
          key: 'roles',
          label: 'Role',
          type: 'badges',
          map: {
            administrator: 'primary',
            customer: 'secondary',
          },
          filter: {
            key: 'userRoles',
            type: 'multiple',
            options: [
              {
                value: 'customer',
                text: 'Customer',
              },
              {
                value: 'super-admin',
                text: 'Super admin',
              },
              {
                value: 'administrator',
                text: 'Admin',
              },
              {
                value: 'finance',
                text: 'Finance',
              },
              {
                value: 'marketer',
                text: 'Marketer',
              },
              {
                value: 'moderator',
                text: 'Moderator',
              },
              {
                value: 'customer-support',
                text: 'Customer support',
              },
              {
                value: 'beta-tester',
                text: 'Beta tester',
              },
            ],
          },
          visibility: ['view'],
        },
        {
          key: 'userType',
          label: 'Type',
          type: 'dot',
          map: {
            creator: 'success',
            supporter: 'danger',
          },
        },
        {
          key: 'tier',
          label: 'Tier',
          type: 'badge',
          map: {
            BOOST_PLUS: 'primary',
            BOOST: 'warning',
            FREE: 'success',
          },
          filter: {
            key: 'tiers',
            type: 'multiple',
            options: [
              {
                value: 'FREE',
                text: 'Free',
              },
              {
                value: 'BOOST',
                text: 'Boost',
              },
              {
                value: 'BOOST_PLUS',
                text: 'Boost Plus',
              },
            ],
          },
        },
        {
          key: 'active_status',
          label: 'Active',
          type: 'dot',
          map: {
            active: 'success',
            inactive: 'danger',
          },
          visibility: ['view'],
          // filter: {
          //   key: 'active',
          //   type: 'select',
          //   options: [
          //     { value: true, text: 'Active' },
          //   ],
          // },
        },
        {
          key: 'verified_status',
          label: 'Email verified',
          type: 'dot',
          map: {
            verified: 'success',
            unverified: 'danger',
          },
          visibility: ['view'],
        },
        {
          key: 'userMetadata.signupComment',
          label: 'Signup Feedback',
        },
        {
          key: 'userInterestsName',
          label: 'Interest',
          type: 'badges',
          map: {},
          visibility: ['view'],
        },
        {
          key: 'tags',
          label: 'Flags',
          type: 'badges',
          map: {
            SFW: 'success',
            NSFW: 'primary',
            PORN: 'primary',
          },
          filter: {
            key: 'tags',
            type: 'select',
            options: [
              { value: 'SFW', text: 'SFW' },
              { value: 'NSFW', text: 'NSFW' },
              { value: 'PORN', text: 'PORN' },
            ],
          },
          visibility: ['view'],
        },
        {
          key: 'isSuspended',
          label: 'Suspend',
        },
        {
          key: 'createdAt',
          label: 'Registered at',
          type: 'datetime',
          filter: {
            startKey: 'startedAt',
            endKey: 'endedAt',
            type: 'datetime',
          },
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
        {
          key: 'deletedAt',
          label: 'Deleted at',
          type: 'datetime',
          visibility: ['view'],
        },
        // TODO: the 'All' value doesn't really show all data. only the not-deleted one.
        {
          label: 'Deleted',
          visibility: false,
          filter: {
            key: 'onlyDeletedUser',
            type: 'select',
            options: [
              {
                value: true,
                text: 'Deleted',
              },
            ],
          },
        },
        {
          label: 'Donation',
          visibility: false,
          filter: {
            key: 'isDonationEnabled',
            type: 'select',
            options: [
              {
                value: true,
                text: 'Enabled',
              },
            ],
          },
        },
        {
          label: 'Membership',
          visibility: false,
          filter: {
            key: 'isMembershipEnabled',
            type: 'select',
            options: [
              {
                value: true,
                text: 'Enabled',
              },
            ],
          },
        },
        {
          label: 'Listing',
          visibility: false,
          // filter: {
          //   key: 'hasListing',
          //   type: 'select',
          //   options: [
          //     { value: true, text: 'Active' },
          //   ],
          // },
        },
      ],

      // Change plan
      changePlanFields: [
        {
          key: 'tier',
          label: 'Tier',
          type: 'radio',
          required: true,
          options: [
            {
              value: 'FREE',
              text: 'Free',
            },
            {
              value: 'BOOST',
              text: 'Boost',
            },
            {
              value: 'BOOST_PLUS',
              text: 'Boost Plus',
            },
          ],
        },
        {
          key: 'subscriptionStart',
          label: 'Started at',
          type: 'datetime',
          required: true,
        },
        {
          key: 'interval',
          label: 'Period',
          type: 'radio',
          required: true,
          options: [
            {
              value: 'month',
              text: 'Monthly',
            },
            {
              value: 'year',
              text: 'Annually',
            },
          ],
        },
      ],

      // User Customization
      changeCustomizationFields: [
        {
          key: 'badges',
          label: 'Badges',
          type: 'multiple',
          options: [
            {
              value: 'tick',
              text: 'tick',
            },
            {
              value: 'verified',
              text: 'verified',
            },
            {
              value: 'gambit',
              text: 'gambit',
            },
          ],
        },
        {
          key: 'badges',
          label: 'Badges',
          type: 'multiple',
          options: [
            {
              value: 'tick',
              text: 'tick',
            },
            {
              value: 'verified',
              text: 'verified',
            },
            {
              value: 'gambit',
              text: 'gambit',
            },
          ],
        },
      ],

      selectedListing: {},

      // User Flaging (NSFW, SFW, PORN)
      selectedProfile: '',
      selectedFlag: '',
      setFlagModalVisible: false,
      setFlagFields: [
        {
          key: 'comment',
          label: 'Comment',
          type: 'textarea',
          minLength: 1,
        },
      ],
      isRemoveFlag: false,

      // User Suspend
      suspendUserModalVisible: false,
      suspendUserModalTitle: '',
      selectedData: '',
    }
  },
  computed: {
    ...mapState('user', [
      'planStatusData',
      'loadingDetail',
      'achievementData',
      'streamAlertData',
      'donationOverlayData',
      'loadingAchievement',
      'walletData',
      'loadingWallet',
      'serviceData',
      'transactionData',
      'withdrawalDetails',
      'loadingService',
      'loadingTransaction',
      'loadingStreamAlert',
      'loadingMediaShare',
      'loadingDonationOverlay',
      'loadingSubmit',
      'loadingWithdrawalDetails',
    ]),
    ...mapState('languages', {
      languageList: ({ list }) => list,
      languageLoadingList: ({ loadingList }) => loadingList,
    }),
    ...mapGetters({
      userData: 'user/detailData',
      mediaShareData: 'user/mediaShareData',
      contractData: 'user/contractData',
    }),
    avatar() {
      return this.$options.filters.thumbnail(this.userData.avatar, 128)
    },
    // TODO: the language detail should be returned from the same endpoint
    userLanguageData() {
      if (this.loadingDetail
        || this.languageLoadingList
        || !this.languageList.length
        || !this.userData
        || !this.userData.profile) {
        return []
      }

      const { languageCodes: userLanguageCodes } = this.userData.profile

      if (!userLanguageCodes) {
        return []
      }

      return this.languageList.filter(({ code }) => userLanguageCodes.includes(code))
    },
    socialMedia() {
      const socialMedia = []

      if (this.loadingDetail || !this.userData || !this.userData.profile) {
        return socialMedia
      }

      if (this.userData.profile.instagramUrl) {
        socialMedia.push({
          name: 'Instagram',
          url: `https://www.instagram.com/${this.userData.profile.instagramUrl}`,
          icon: instagramIcon,
        })
      }

      if (this.userData.profile.facebookUrl) {
        socialMedia.push({
          name: 'Facebook',
          url: `https://www.facebook.com/${this.userData.profile.facebookUrl}`,
          icon: facebookIcon,
        })
      }

      if (this.userData.profile.twitterUrl) {
        socialMedia.push({
          name: 'Twitter',
          url: `https://www.twitter.com/${this.userData.profile.twitterUrl}`,
          icon: twitterIcon,
        })
      }

      if (this.userData.profile.tiktokUrl) {
        socialMedia.push({
          name: 'Tiktok',
          url: `https://www.tiktok.com/${this.userData.profile.tiktokUrl}`,
          icon: tiktokIcon,
        })
      }

      if (this.userData.profile.twitchUrl) {
        socialMedia.push({
          name: 'Twitch',
          url: `https://www.twitch.tv/${this.userData.profile.twitchUrl}`,
          icon: twitchIcon,
        })
      }

      if (this.userData.profile.youtubeUrl) {
        socialMedia.push({
          name: 'Youtube',
          url: `https://www.youtube.com/${this.userData.profile.youtubeUrl}`,
          icon: youtubeIcon,
        })
      }

      if (this.userData.profile.discordUrl) {
        socialMedia.push({
          name: 'Discord',
          url: `https://www.discord.gg/${this.userData.profile.discordUrl}`,
          icon: discordIcon,
        })
      }

      if (this.userData.profile.steamUrl) {
        socialMedia.push({
          name: 'Steam',
          url: `https://www.steamcommunity.com/${this.userData.profile.steamUrl}`,
          icon: steamIcon,
        })
      }

      if (this.userData.profile.otherUrl) {
        socialMedia.push({
          name: 'Other',
          url: `${this.userData.profile.steamUrl}`,
          icon: otherIcon,
        })
      }

      return socialMedia
    },
    walletCredit() {
      if (this.loadingWallet || !this.walletData.length) {
        return null
      }

      return this.walletData.find(({ type }) => type === 'credit')
    },
    walletEarning() {
      if (this.loadingWallet || !this.walletData.length) {
        return null
      }

      return this.walletData.find(({ type }) => type === 'earning')
    },
    contractStatus() {
      const currentDate = new Date()
      const startDate = new Date(this.contractData.start)
      const expirationDate = new Date(this.contractData.end)

      if (!this.contractData.start) {
        return 'organic'
      }

      if (currentDate > expirationDate) {
        return 'ended'
      }
      return startDate > currentDate ? 'waiting' : 'active'
    },
    updateContractFields() {
      return [
        {
          key: 'start',
          label: 'Started at',
          type: 'datetime',
          value: this.contractData.start,
          required: true,
        },
        {
          key: 'end',
          label: 'Ended at',
          type: 'datetime',
          value: this.contractData.end,
          required: true,
        },
      ]
    },
    canUpdateContract() {
      return this.canAccess('manage', 'UpdateContract')
    },
  },
  mounted() {
    const { id } = this.$route.params
    this.loadData(id)

    this.loadLanguageData()
  },
  methods: {
    loadData(id) {
      this.loadDetailUser()
      if (this.canAccess('manage', 'Withdrawal')) {
        this.loadDetailUserWithdrawal()
      }
      if (this.canAccess('manage', 'CreatorPlan')) {
        this.loadPlanStatus()
      }
      this.$store.dispatch('user/getGameData', id)
      this.$store.dispatch('user/getAchievementData', id)
      this.$store.dispatch('user/getWalletData', id)

      this.resetServiceData()
      this.loadServiceData()
      this.loadStreamAlert()
      this.loadDonationOverlay()
      this.loadMediaShare()

      this.resetTransactionData()
      this.loadTransactionData()
    },
    async loadDetailUser() {
      await this.$store.dispatch('user/getDetail', this.$route.params.id)

      this.selectedRoles = this.userData.roles
        .filter(item => item)
        .map(item => ({
          value: item,
          text: this.roleOptions.find(role => role.value === item).text,
        }))

      if (this.userData
        && this.userData.profile
        && this.userData.profile.customization) {
        this.selectedBadges = this.userData.profile.customization.badges.length ? this.userData.profile.customization.badges
          .filter(item => item)
          .map(item => ({
            value: item,
            text: this.badgesOptions.find(badges => badges.value === item).text,
          })) : []
        this.selectedBorderOptions = this.userData.profile.customization.border.options.length ? this.userData.profile.customization.border.options
          .filter(item => item)
          .map(item => ({
            value: item,
            text: this.bordersOptions.find(badges => badges.value === item).text,
          })) : []
        this.selectedBorder = {
          value: this.userData.profile.customization.border.selected,
          text: this.bordersOptions.find(border => border.value === this.userData.profile.customization.border.selected).text,
        }
        this.selectedEmojis = this.userData.profile.customization.emojis.length ? this.userData.profile.customization.emojis
          .filter(item => item)
          .map(item => ({
            value: item,
            text: this.emojisOptions.find(badges => badges.value === item).text,
          })) : []
      }
    },
    async loadDetailUserWithdrawal() {
      await this.$store.dispatch('user/getDetailWithdrawal', this.$route.params.id)
    },
    loadPlanStatus() {
      this.$store.dispatch('user/getPlanStatus', this.$route.params.id)
    },
    loadLanguageData() {
      this.$store.dispatch('languages/getList')
    },
    loadNextTransactionData() {
      this.$store.commit('user/SET_TRANSACTION_PAGE', this.$store.state.user.transactionPage + 1)
      this.loadTransactionData()
    },
    resetTransactionData() {
      this.$store.commit('user/SET_TRANSACTION_DATA', [])
    },
    loadTransactionData() {
      this.$store.dispatch('user/getTransactionData', this.$route.params.id)
    },
    loadStreamAlert() {
      this.$store.dispatch('user/getStreamAlert', this.$route.params.id)
    },
    loadDonationOverlay() {
      this.$store.dispatch('user/getDonationOverlay', this.$route.params.id)
    },
    loadMediaShare() {
      this.$store.dispatch('user/getMediaShare', this.$route.params.id)
    },
    loadNextServiceData() {
      this.$store.commit('user/SET_SERVICE_PAGE', this.$store.state.user.servicePage + 1)
      this.loadServiceData()
    },
    resetServiceData() {
      this.$store.commit('user/SET_SERVICE_DATA', [])
    },
    loadServiceData() {
      this.$store.dispatch('user/getServiceData', this.$route.params.id)
    },
    confirmResetPassword() {
      this.resetPasswordModalVisible = true
    },
    showChangeRole() {
      this.selectedRoles = this.userData.roles.map(item => ({
        value: item,
        text: this.roleOptions.find(role => role.value === item).text,
      }))

      this.changeRoleModalVisible = true
    },
    async saveRole() {
      const roles = this.selectedRoles.map(item => item.value)
      const { id } = this.$route.params

      await this.$store.dispatch('user/replaceRoles', {
        id,
        roles,
      })
        .then(() => {
          this.changeRoleModalVisible = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'User role changed!',
              variant: 'success',
            },
          })

          // reload user data
          this.$store.dispatch('user/getDetail', id)
        })
    },
    async confirmRole() {
      this.roleConfirmationModalVisible = true
    },
    confirmDelete() {
      this.deleteModalVisible = true
    },
    deleteUser() {
      this.$store.dispatch('user/delete', this.userData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The user has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.$router.replace({ name: 'user' })
        })
    },
    resetPassword() {
      this.$store.dispatch('user/forgotPassword', {
        email: this.userData.email,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Please ask the user to check their email inbox or spam',
              variant: 'success',
            },
          })

          this.resetPasswordModalVisible = false
        })
    },
    changePlan() {
      this.changePlanModalVisible = true
    },
    updateContract() {
      this.updateContractVisible = true
    },
    submitContract(id, data) {
      const payload = {
        contractStart: moment(data.start)
          .utc()
          .toISOString(),
        contractEnd: moment(data.end)
          .utc()
          .toISOString(),
      }

      try {
        this.$store.dispatch('user/updateCreatorContract', { id, payload })
      } finally {
        this.updateContractVisible = false
      }
    },
    submitChangePlan(id, data) {
      const amount = 1
      const paymentMethod = this.planStatusData.paymentMethod ? this.planStatusData.paymentMethod : 'manual'
      const paymentMethodID = this.planStatusData.paymentMethodID ? this.planStatusData.paymentMethodID : ''
      const isRenewal = this.planStatusData.isRenewal ? this.planStatusData.isRenewal : false

      this.$store.dispatch('user/changePlan', {
        ...data,
        userID: id,
        subscriptionStart: moment(data.subscriptionStart)
          .utc()
          .toISOString(),
        amount,
        paymentMethod,
        paymentMethodID,
        isRenewal,
      })
        .then(() => {
          this.loadDetailUser()
          this.loadPlanStatus()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'User\'s plan updated!',
              variant: 'success',
            },
          })

          this.changePlanModalVisible = false
        })
    },
    changeCustomization() {
      this.changeCustomizationModalVisible = true
    },
    showAllTransaction() {
      this.$store.commit('transactionV2/SET_TABLE_FILTER', [
        {
          key: 'search_keywords',
          value: this.userData.nickname,
          retain: true,
        },
      ])

      this.$router.push('/transaction-v2')
    },
    async showDetailTransaction(id) {
      await this.$store.commit('appConfig/UPDATE_TABLE_PER_PAGE', 10)
      this.$store.commit('transactionV2/SET_TABLE_PAGINATION', {
        currentPage: this.$store.state.user.transactionPage,
        perPage: 10,
      })
      this.$store.commit('transactionV2/SET_TABLE_FILTER', [
        {
          key: 'search_keywords',
          value: this.userData.nickname,
          retain: true,
        },
      ])

      this.$router.push(`/transaction-v2#${id}`)
    },
    async saveCustomization() {
      const badges = this.selectedBadges.map(item => item.value)
      const borderOptions = this.selectedBorderOptions.map(item => item.value)
      const selectedBorder = this.selectedBorder.value
      const emojis = this.selectedEmojis.map(item => item.value)
      const { id } = this.$route.params
      const data = {
        profile: {
          customization: {
            badges,
            border: {
              options: borderOptions,
              selected: selectedBorder,
            },
            emojis,
          },
        },
      }
      await this.$store.dispatch('user/update', {
        id,
        data,
      })
        .then(() => {
          this.changeCustomizationModalVisible = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'User customization changed!',
              variant: 'success',
            },
          })

          // reload user data
          this.$store.dispatch('user/getDetail', id)
        })
    },
    requestReloadSession() {
      this.reloadSessionModalVisible = true
    },
    reloadSession() {
      this.loadingReloadSession = true

      this.forceSession()
        .then(() => {
          // reload the widget page after 1s
          setTimeout(() => {
            this.loadingReloadSession = false
            this.reloadSessionModalVisible = false

            this.reloadStreamAlert()
            this.reloadMediaShare()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                text: 'The session has been reloaded!',
                variant: 'success',
              },
            })
          }, 250)
        })
        .catch(() => {
          this.loadingReloadSession = false
        })
    },
    forceSession() {
      const { id } = this.$route.params
      return this.$http.get(`/v1/users/user-media-share-queue/force-session/${id}`)
    },
    selectListing(item) {
      if (item.isActive) {
        this.selectedListing = item
        this.inactiveListingModalVisible = true
      } else {
        this.updateListingStatus(item)
      }
    },
    async updateListingStatus(item) {
      const newStatus = !item.isActive

      const payload = {
        ...item,
        isActive: newStatus,
      }
      await this.$store.dispatch('service/update', { id: item.id, data: payload })
        .then(() => {
          const index = this.serviceData.findIndex(service => service.id === item.id)

          if (index !== -1) {
            this.serviceData[index].isActive = newStatus
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Listing status has been updated',
              variant: 'success',
            },
          })
        })
    },
    reloadStreamAlert() {
      const { id } = this.$route.params
      return this.$http.post(`/v1/users/user-media-share/update-controller/${id}`, { type: 'reload_stream_alert' })
    },
    reloadMediaShare() {
      const { id } = this.$route.params
      return this.$http.post(`/v1/users/user-media-share/update-controller/${id}`, { type: 'reload_media_share' })
    },
    openStreamAlert() {
      const { id } = this.$route.params
      window.open(`/widget/${id}`, 'Stream Alert', 'width=800,height=600')
    },
    openDonationOverlay() {
      const { id } = this.$route.params
      window.open(`/widget/${id}/donation-goal`, 'Donation Overlay', 'width=800,height=200')
    },
    openMediaShare() {
      const { id } = this.$route.params
      window.open(`/widget/${id}/media-share`, 'Media Share', 'width=800,height=600')
    },
    openIdCard() {
      window.open(this.withdrawalDetails.verificationPictureURL)
    },
    testAlert(name) {
      // fallback value is MAIN
      let variation = this.streamAlertData

      if (name !== 'MAIN') {
        variation = this.streamAlertData.variations.find(item => item.name === name)
      }

      const data = {
        creatorId: this.$route.params.id,
        donorName: 'Someone',
        donorAmount: 1,
        donorMessage: 'Happy streaming!',
        streamAlertVariationTest: {
          name,
          filter: variation.condition,
          filter_data: {
            MinUsd: variation.minDonationAmount,
          },
        },
      }

      this.$http.post('/v1/users/user-media-share-queue/alert-test', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Test alert has been sent!',
              variant: 'success',
            },
          })
        })
    },
    verify(status, reason) {
      const { id } = this.$route.params
      this.$store.dispatch('user/verifyID', { id, status, reason })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `The verification has been ${status ? 'approved' : 'rejected'}`,
              variant: `${status ? 'success' : 'danger'}`,
            },
          })

          this.verifiedIdModalVisible = false
          this.rejectIdModalVisible = false
          this.rejectReason = ''
          this.loadDetailUserWithdrawal()
        })
    },
    patchProfile(payload, type) {
      const message = {
        flag: `${this.selectedFlag} tag has been ${this.isRemoveFlag ? 'removed' : 'added'}!`,
        profileStat: 'User has been deactived',
        profileSuspend: 'User has been suspended',
      }

      this.$store.dispatch('manualProfileReview/setProfileFlag', { id: this.selectedProfile?.id, payload })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: message[type],
              variant: 'success',
            },
          })

          this.setFlagModalVisible = false
          this.loadData()
        })
    },
    access() {
      return this.canAccess('manage', 'ManualProfileReview')
    },
    canManage() {
      return { callback: () => this.access }
    },
    canFlag(type) {
      return { callback: data => !data?.tags.includes(type) && this.access }
    },
    canRemoveFlag(type) {
      return { callback: data => data?.tags.includes(type) && this.access }
    },
    updateProfileFlag(val, flagType, remove) {
      this.isRemoveFlag = !!remove
      this.selectedProfile = {
        id: val?.id,
        tags: val?.tags || [],
      }
      this.selectedFlag = flagType
      this.setFlagModalVisible = true
    },
    setFlag(data) {
      let updatedTags = []

      if (this.isRemoveFlag) {
        updatedTags = this.selectedProfile.tags.filter(tag => tag !== this.selectedFlag)
      } else {
        updatedTags = [this.selectedFlag]
      }

      const payload = {
        comment: data?.comment,
        tags: updatedTags,
      }

      this.patchProfile(payload, 'flag')
    },
    updateProfileSuspend(data) {
      this.selectedData = data

      if (this.selectedData?.isSuspended) {
        this.suspendUserModalTitle = 'Unsuspend'
      } else {
        this.suspendUserModalTitle = 'Suspend'
      }
      this.suspendUserModalVisible = true
    },
    suspendUser() {
      const payload = {
        isSuspended: !this.selectedData?.isSuspended,
      }
      this.$store.dispatch('manualProfileReview/setProfileFlag', { id: this.selectedData?.id, payload })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `Success ${this.selectedData?.isSuspended ? 'Unsuspend' : 'Suspend'} User`,
              variant: 'success',
            },
          })

          this.suspendUserModalVisible = false
          this.loadData()
        })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
