import countries from '@/list/countries'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'nickname',
    label: 'Creator Username',
    type: 'profile',
    image: 'avatar',
    visibility: ['view'],
  },
  {
    key: 'profileUrl',
    label: 'Profile link',
    type: 'url',
    sortable: false,
    url: {
      callback(data) {
        return `${process.env.VUE_APP_CLIENT_URL}/${data.nickname}`
      },
    },
  },
  {
    key: 'createdAt',
    label: 'Signup Date',
    type: 'datetime',
  },
  {
    key: 'profile.countryCode',
    label: 'Country Location',
    type: 'country',
    filter: {
      key: 'countryCode',
      type: 'select',
      options: countries,
    },
  },
  {
    key: 'nsfwImage.type',
    label: 'Content Type',
    visibility: ['view'],
  },
  {
    key: 'nsfwImage.upload_time', // temp key, response still empty from API
    label: 'Upload Date & Item',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'tags',
    label: 'Flags',
    type: 'badges',
    map: {
      // SFW: 'success',
      // NSFW: 'primary',
      PORN: 'primary',
    },
    filter: {
      key: 'tags',
      type: 'select',
      options: [
        // { value: 'SFW', text: 'SFW' },
        { value: 'NSFW', text: 'NSFW' },
        { value: 'PORN', text: 'PORN' },
      ],
    },
    visibility: ['view'],
  },
  {
    key: 'active_status',
    label: 'Status',
    type: 'dot',
    map: {
      active: 'success',
      deactive: 'danger',
    },
    filter: {
      key: 'active',
      type: 'select',
      options: [
        { value: true, text: 'Active' },
        { value: false, text: 'Deactive' },
      ],
    },
  },
  {
    key: 'isSuspended',
    label: 'Suspend',
    type: 'dot',
    map: {
      No: 'success',
      Suspended: 'danger',
    },
    filter: {
      key: 'isSuspended',
      type: 'select',
      options: [
        { value: true, text: 'Suspended' },
      ],
    },
  },
  {
    key: 'nsfwImage.url', // temp key, response still empty from API
    label: 'View Image',
    type: 'image',
    visibility: ['view'],
  },
  {
    key: 'nsfwCheckDate',
    label: 'Auto Tag Date',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'adminComment',
    label: 'Comments',
    visibility: ['view'],
  },
]
