import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/user'

export default {
  namespaced: true,
  state: {
    tableFields: get('userTableFields', fields),
    tableData: [],
    tablePagination: get('userTablePagination'),
    tableKeyword: get('userTableKeyword'),
    tableSort: get('userTableSort'),
    tableFilter: get('userTableFilter', []),
    detailData: get('userDetailData'),
    planStatusData: get('userPlanStatusData'),
    contractData: {
      type: undefined,
      start: undefined,
      end: undefined,
    },
    gameData: get('userGameData', []),
    achievementData: get('userAchievementData', []),
    streamAlertData: get('userStreamAlertData'),
    donationOverlayData: get('userDonationOverlayData'),
    mediaShareData: get('userMediaShareData'),
    walletData: get('userWalletData', []),
    serviceData: get('userServiceData', []),
    transactionData: get('userTransactionData', []),
    withdrawalDetails: get('userWithdrawalDetails'),
    usernameTrackerList: [],
    transactionPage: 1,
    transactionHasNextPage: false,
    servicePage: 1,
    serviceHasNextPage: false,
    loadingTable: false,
    loadingDetail: false,
    loadingPlanStatus: false,
    loadingGame: false,
    loadingAchievement: false,
    loadingWallet: false,
    loadingService: false,
    loadingTransaction: false,
    loadingSubmit: false,
    loadingStreamAlert: false,
    loadingMediaShare: false,
    loadingDonationOverlay: false,
    loadingUsernameTracker: false,
    loadingWithdrawalDetails: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      return `${state.tableSort.key} ${state.tableSort.direction}`
    },
    tableData: state => {
      if (!state.tableData) {
        return []
      }

      return state.tableData.map(item => ({
        ...item,
        userInterestsName: item.userInterests.map(({ name }) => name),
        fullName: [item.firstName, item.lastName]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
        active_status: item.active ? 'active' : 'inactive',
        verified_status: item.verified ? 'verified' : 'unverified',
        isSuspended: (item.isSuspended ?? false) ? 'Suspended' : 'No',
        tags: item.tags || [],
      }))
    },
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      const userInterest = _get(state, 'detailData.userInterests') || []

      return {
        ...state.detailData,
        userInterestsName: userInterest.map(({ name }) => name),
        fullName: [_get(state, 'detailData.firstName'), _get(state, 'detailData.lastName')]
          .filter(name => name)
          .join(' ')
          .replace('  ', ' '), // remove double space
        active_status: _get(state, 'detailData.active') ? 'active' : 'inactive',
        verified_status: _get(state, 'detailData.verified') ? 'verified' : 'unverified',
        tags: _get(state, 'detailData.tags') || [],
        verified_ID: _get(state, 'detailData.verified') ? 'verified' : 'unverified',
      }
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [elem.key]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      keyword: state.tableKeyword,
      ...getters.tableFilter,
      isShowDeletedUser: true, // BE should implement this as default
      orderBy: getters.tableSort,
      page: _get(state, 'tablePagination.currentPage', 1),
      perPage: rootState.appConfig.settings.tablePerPage,
    }),
    mediaShareData: state => {
      if (!state.mediaShareData) {
        return undefined
      }

      const mapSecurity = ['Off', 'Low', 'Medium', 'High', 'Very High']

      return {
        ...state.mediaShareData,
        manualApprove: !state.mediaShareData.autoApprove,
        spamSecurityLevel: mapSecurity[state.mediaShareData.spamSecurity],
      }
    },
    contractData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.contractData,
        type: _get(state, 'detailData.creatorType'),
        start: _get(state, 'detailData.creatorContract.contractStart'),
        end: _get(state, 'detailData.creatorContract.contractEnd'),
      }
    },
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('userTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('userTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('userTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('userTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('userDetailData', val)
      state.detailData = val
    },
    SET_PLAN_STATUS_DATA(state, val) {
      set('userPlanStatusData', val)
      state.planStatusData = val
    },
    SET_GAME_DATA(state, val) {
      set('userGameData', val)
      state.gameData = val
    },
    SET_STREAM_ALERT_DATA(state, val) {
      set('userStreamAlertData', val)
      state.streamAlertData = val
    },
    SET_DONATION_OVERLAY_DATA(state, val) {
      set('userDonationOverlayData', val)
      state.donationOverlayData = val
    },
    SET_MEDIA_SHARE_DATA(state, val) {
      set('userMediaShareData', val)
      state.mediaShareData = val
    },
    SET_WALLET_DATA(state, val) {
      set('userWalletData', val)
      state.walletData = val
    },
    SET_ACHIEVEMENT_DATA(state, val) {
      set('userAchievementData', val)
      state.achievementData = val
    },
    SET_SERVICE_DATA(state, val) {
      set('userServiceData', val)
      state.serviceData = val
    },
    SET_TRANSACTION_DATA(state, val) {
      set('userTransactionData', val)
      state.transactionData = val
    },
    SET_WITHDRAWAL_DETAILS(state, val) {
      set('userWithdrawalDetails', val)
      state.withdrawalDetails = val
    },
    SET_TABLE_SORT(state, val) {
      set('userTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('userTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_PLAN_STATUS(state, val) {
      state.loadingPlanStatus = val
    },
    SET_LOADING_GAME(state, val) {
      state.loadingGame = val
    },
    SET_LOADING_ACHIEVEMENT(state, val) {
      state.loadingAchievement = val
    },
    SET_LOADING_STREAM_ALERT(state, val) {
      state.loadingStreamAlert = val
    },
    SET_DONATION_OVERLAY_ALERT(state, val) {
      state.loadingDonationOverlay = val
    },
    SET_LOADING_WALLET(state, val) {
      state.loadingWallet = val
    },
    SET_LOADING_SERVICE(state, val) {
      state.loadingService = val
    },
    SET_LOADING_TRANSACTION(state, val) {
      state.loadingTransaction = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
    SET_LOADING_MEDIA_SHARE(state, val) {
      state.loadingMediaShare = val
    },
    SET_TRANSACTION_PAGE(state, val) {
      state.transactionPage = val
    },
    SET_TRANSACTION_HAS_NEXT_PAGE(state, val) {
      state.transactionHasNextPage = val
    },
    SET_SERVICE_PAGE(state, val) {
      state.servicePage = val
    },
    SET_SERVICE_HAS_NEXT_PAGE(state, val) {
      state.serviceHasNextPage = val
    },
    SET_USERNAME_TRACKER_LIST(state, val) {
      state.usernameTrackerList = val
    },
    SET_LOADING_USERNAME_TRACKER(state, val) {
      state.loadingUsernameTracker = val
    },
    SET_LOADING_WITHDRAWAL_DETAILS(state, val) {
      state.loadingWithdrawalDetails = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/bo/users/', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data || [])
          commit('SET_TABLE_PAGINATION', data._meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    async getDetail({ commit }, id) {
      try {
        commit('SET_LOADING_DETAIL', true)

        const res = await this._vm.$http.get(`/v1/bo/users/${id}`)
        commit('SET_DETAIL_DATA', res.data.data)
      } catch (error) {
        commit('SET_LOADING_DETAIL', false)
      } finally {
        commit('SET_LOADING_DETAIL', false)
      }
    },
    async getDetailWithdrawal({ commit }, id) {
      try {
        commit('SET_LOADING_WITHDRAWAL_DETAILS', true)

        const res = await this._vm.$http.get(`/v1/users/${id}/verify-withdrawal-account`)
        commit('SET_WITHDRAWAL_DETAILS', res.data.data)
      } catch (error) {
        commit('SET_LOADING_WITHDRAWAL_DETAILS', false)
      } finally {
        commit('SET_LOADING_WITHDRAWAL_DETAILS', false)
      }
    },
    getPlanStatus({ commit }, id) {
      commit('SET_LOADING_PLAN_STATUS', true)

      this._vm.$http.get(`/v1/users/${id}/tier/status`)
        .then(({ data }) => {
          commit('SET_PLAN_STATUS_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_PLAN_STATUS', false)
        })
    },
    getGameData({ commit }, id) {
      commit('SET_LOADING_GAME', true)

      this._vm.$http.get(`/v1/users/${id}/games`)
        .then(({ data }) => {
          commit('SET_GAME_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_GAME', false)
        })
    },
    getAchievementData({ commit }, id) {
      commit('SET_LOADING_ACHIEVEMENT', true)

      this._vm.$http.get(`/v1/users/${id}/achievements`)
        .then(({ data }) => {
          commit('SET_ACHIEVEMENT_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_ACHIEVEMENT', false)
        })
    },
    getStreamAlert({ commit }, id) {
      commit('SET_LOADING_STREAM_ALERT', true)

      this._vm.$http.get(`/v1/users/${id}/user-donation-setting`, {
        params: {
          _alert: 'hide',
        },
      })
        .then(({ data }) => {
          commit('SET_STREAM_ALERT_DATA', data.data)
        })
        .catch(() => {
          commit('SET_STREAM_ALERT_DATA', null)
        })
        .finally(() => {
          commit('SET_LOADING_STREAM_ALERT', false)
        })
    },
    getDonationOverlay({ commit }, id) {
      commit('SET_DONATION_OVERLAY_ALERT', true)

      this._vm.$http.get(`/v1/users/user-donation-overlay/${id}`, {
        params: {
          _alert: 'hide',
        },
      })
        .then(({ data }) => {
          commit('SET_DONATION_OVERLAY_DATA', data.data)
        })
        .finally(() => {
          commit('SET_DONATION_OVERLAY_ALERT', false)
        })
    },
    getMediaShare({ commit }, id) {
      commit('SET_LOADING_MEDIA_SHARE', true)

      this._vm.$http.get(`/v1/users/user-media-share-settings/${id}`, {
        params: {
          _alert: 'hide',
        },
      })
        .then(({ data }) => {
          commit('SET_MEDIA_SHARE_DATA', data.data)
        })
        .catch(() => {
          commit('SET_MEDIA_SHARE_DATA', null)
        })
        .finally(() => {
          commit('SET_LOADING_MEDIA_SHARE', false)
        })
    },
    getWalletData({ commit }, id) {
      commit('SET_LOADING_WALLET', true)

      // TODO: Should not use filtered list data
      // use like /v1/users/{id}/wallet instead
      this._vm.$http.get('/v1/wallets', {
        params: {
          userID: id,
        },
      })
        .then(({ data }) => {
          commit('SET_WALLET_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_WALLET', false)
        })
    },
    getServiceData({ state, commit }, id) {
      commit('SET_LOADING_SERVICE', true)

      // TODO: Should not use filtered list data
      // use like /v1/users/{id}/service instead
      this._vm.$http.get('/v1/bo/users/services', {
        params: {
          page: state.servicePage,
          userID: id,
          perPage: 6,
          orderBy: 'createdAt desc',
        },
      })
        .then(({ data }) => {
          commit('SET_SERVICE_DATA', [
            ...state.serviceData,
            ...data.data,
          ])
          commit('SET_SERVICE_HAS_NEXT_PAGE', data._meta.currentPage < data._meta.totalPage)
        })
        .finally(() => {
          commit('SET_LOADING_SERVICE', false)
        })
    },
    getTransactionData({ state, commit }, id) {
      commit('SET_LOADING_TRANSACTION', true)

      // TODO: Should not use filtered list data
      // use like /v1/users/{id}/transaction instead
      this._vm.$http.get('/v2/payment/admin/transactions', {
        params: {
          page: state.transactionPage,
          search_keywords: id,
          per_page: 10,
          order_by: 'created_at desc',
        },
      })
        .then(({ data }) => {
          commit('SET_TRANSACTION_DATA', [
            ...state.transactionData,
            ...data.data,
          ])
          commit('SET_TRANSACTION_HAS_NEXT_PAGE', (state.transactionPage * 10) < data.total)
        })
        .finally(() => {
          commit('SET_LOADING_TRANSACTION', false)
        })
    },
    update({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/bo/users/${id}`, data)
        .then(res => {
          commit('SET_DETAIL_DATA', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/users', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    updatePassword({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/users/change-password', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    forgotPassword({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/users/forgot-password', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    delete({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v1/users/${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    replaceRoles({ commit }, { id, roles }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post(`/v1/bo/users/${id}/replace-roles`, {
        roles,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    changePlan({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/users/tier/upgrade-manual', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    async getUsernameTrackerList({ commit }, username) {
      try {
        commit('SET_LOADING_USERNAME_TRACKER', true)

        const res = await this._vm.$http.get(`/v1/bo/users/nickname-histories/${username}`, {
          params: {
            _alert: 'hide',
          },
        })
        commit('SET_USERNAME_TRACKER_LIST', res.data.data)
      } catch (error) {
        commit('SET_LOADING_USERNAME_TRACKER', false)
        commit('SET_USERNAME_TRACKER_LIST', [])
      } finally {
        commit('SET_LOADING_USERNAME_TRACKER', false)
      }
    },
    changeNsfw({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/bo/users/${id}`, {
        tags: data,
      })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    search(_, params) {
      return this._vm.$http.get('/v1/bo/users', { params })
        .then(({ data }) => data.data.map(item => ({
          ...item,
          // replace option text key here
          text: item.nickname || item.email,
        })))
        .catch(() => [])
    },
    verifyID({ commit }, { id, status, reason }) {
      commit('SET_LOADING_SUBMIT', true)
      return this._vm.$http.patch(`/v1/users/${id}/verify-withdrawal-account`, { userID: id, verified: status, rejectReason: reason })
        .then(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    async updateCreatorContract({ commit, state }, { id, payload }) {
      try {
        const { data } = await this._vm.$http.post(`/v1/bo/users/${id}/creator-contract`, payload)
        const creatorContract = data.data
        const updatedDetailData = {
          ...state.detailData,
          creatorContract: {
            ...state.detailData.creatorContract,
            ...creatorContract,
          },
        }
        commit('SET_DETAIL_DATA', updatedDetailData)
      } catch (error) {
        commit('SET_DETAIL_DATA', null)
      }
    },
  },
}
