<template>
  <div>
    <Table
      title-key="nickname"
      :actions="actions"
      :items="$store.getters['user/tableData']"
      :detail="$store.getters['user/detailData']"
      :fields="$store.getters['user/tableFields']"
      :loading="$store.state.user.loadingTable"
      :filter="$store.state.user.tableFilter"
      :sort="$store.state.user.tableSort"
      :sort-options="sortOptions"
      :pagination="$store.state.user.tablePagination"
      :keyword="$store.state.user.tableKeyword"
      search-placeholder="Search email, username"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @loadDetail="loadDetail"
      @resetDetail="resetDetail"
      @paginate="paginate"
      @search="search"
    />

    <!-- Reset Password confirmation modal -->
    <b-modal
      v-model="resetPasswordModalVisible"
      title="Reset Password"
      ok-title="Yes, reset"
      cancel-variant="white"
      @ok="resetPassword"
    >
      A URL to reset the password will be sent to their email. Are you sure?.
    </b-modal>

    <!-- Delete User confirmation modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete User"
      hide-footer
    >
      Are you sure want to delete this user?. This action cannot be undone.
      <FormBuilder
        :fields="setFlagFields"
        :success-message="`User has been deleted!`"
        submit-label="Yes"
        block
        @submit="deleteUser"
        @cancel="deleteModalVisible = false"
      />
    </b-modal>

    <!-- Delete User confirmation modal -->
    <b-modal
      v-model="nsfwModalVisible"
      title="Add NSFW Tag"
      hide-footer
    >
      <p>{{ `Add an NSFW tag to this user. Are you sure?` }}</p>
      <FormBuilder
        :fields="setFlagFields"
        :success-message="`Tag has been added!`"
        submit-label="Yes"
        block
        @submit="markNSFW"
        @cancel="nsfwModalVisible = false"
      />
    </b-modal>

    <!-- Delete User confirmation modal -->
    <b-modal
      v-model="unNsfwModalVisible"
      title="Remove NSFW Tag"
    >
      <p>Remove this NSFW tag from this user. Are you sure?</p>
      <FormBuilder
        :fields="setFlagFields"
        :success-message="`Tag has been removed!`"
        submit-label="Yes"
        block
        @submit="unmarkNSFW"
        @cancel="unNsfwModalVisible = false"
      />
    </b-modal>

    <!-- Suspend User confirmation modal -->
    <b-modal
      v-model="suspendUserModalVisible"
      :title="`${suspendUserModalTitle} User`"
      hide-footer
    >
      <p>{{ `Are you sure want ${suspendUserModalTitle} to this user?` }}</p>
      <FormBuilder
        :fields="setFlagFields"
        :success-message="`User has been ${suspendUserModalTitle}!`"
        submit-label="Yes"
        block
        @submit="suspendUser"
        @cancel="suspendUserModalVisible = false"
      />
    </b-modal>

    <!-- Flag NSFW, SFW, PORN modal  -->
    <b-modal
      v-model="setFlagModalVisible"
      :title="`${isRemoveFlag ? 'Remove' : 'Add'} ${selectedFlag} Tag`"
      hide-footer
    >
      <p>{{ `${isRemoveFlag ? 'Remove' : 'Add'} an ${selectedFlag} tag to this user. Are you sure?` }}</p>
      <FormBuilder
        :fields="setFlagFields"
        :loading-submit="$store.state.manualProfileReview.loadingSubmit"
        :success-message="`${selectedFlag} tag has been ${isRemoveFlag ? 'removed' : 'added'}!`"
        submit-label="Yes"
        block
        @submit="setFlag"
        @cancel="setFlagModalVisible = false"
      />
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import FormBuilder from '@/layouts/components/FormBuilder.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
    FormBuilder,
  },
  data() {
    return {
      resetPasswordModalVisible: false,
      deleteModalVisible: false,
      nsfwModalVisible: false,
      unNsfwModalVisible: false,
      suspendUserModalVisible: false,
      selectedData: '',
      suspendUserModalTitle: '',
      selectedProfile: '',
      selectedFlag: '',
      setFlagModalVisible: false,
      setFlagFields: [
        {
          key: 'comment',
          label: 'Comment',
          type: 'textarea',
          minLength: 1,
        },
      ],
      isRemoveFlag: false,

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: [
        'create',
        'view',
        'update',
        {
          type: 'other',
          children: [
            {
              label: 'Suspend User',
              event: data => this.updateProfileSuspend(data),
              visibility: {
                callback: data => (data?.isSuspended === 'No' || data?.isSuspended === undefined) && this.canAccess('manage', 'User'),
              },
              tooltipText: 'Suspend an account means to hide the profile from the public, but the account owner can still access it to make changes to the content.',
            },
            {
              label: 'Unsuspend User',
              event: data => this.updateProfileSuspend(data),
              visibility: {
                callback: data => data?.isSuspended === 'Suspended' && this.canAccess('manage', 'User'),
              },
            },
            {
              label: 'Reset Password',
              event: this.confirmResetPassword,
              visibility: {
                callback: data => !data.deletedAt && this.canAccess('manage', 'User'),
              },
            },
            {
              label: 'Flag as SFW',
              event: data => this.updateProfileFlag(data, 'SFW'),
              visibility: this.canFlag('SFW'),
            },
            {
              label: 'Remove SFW tags',
              event: data => this.updateProfileFlag(data, 'SFW', true),
              visibility: this.canRemoveFlag('SFW'),
            },
            {
              label: 'Flag as NSFW',
              event: data => this.updateProfileFlag(data, 'NSFW'),
              visibility: this.canFlag('NSFW'),
            },
            {
              label: 'Remove NSFW tags',
              event: data => this.updateProfileFlag(data, 'NSFW', true),
              visibility: this.canRemoveFlag('NSFW'),
            },
            {
              label: 'Flag as Porn',
              event: data => this.updateProfileFlag(data, 'PORN'),
              visibility: this.canFlag('PORN'),
            },
            {
              label: 'Remove Porn tags',
              event: data => this.updateProfileFlag(data, 'PORN', true),
              visibility: this.canRemoveFlag('PORN'),
            },
            {
              label: 'Delete',
              event: this.confirmDelete,
              visibility: {
                callback: data => !data.deletedAt && this.canAccess('manage', 'User'),
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('user/getTableData')
    },
    loadDetail(id) {
      return this.$store.dispatch('user/getDetail', id)
    },
    resetDetail() {
      this.$store.commit('user/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('user/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('user/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('user/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('user/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('user/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('user/SET_TABLE_KEYWORD', val)
    },
    resetPassword() {
      this.$store.dispatch('user/forgotPassword', {
        email: this.selectedData.email,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Please ask the user to check their email inbox or spam',
              variant: 'success',
            },
          })

          this.resetPasswordModalVisible = false
        })
    },
    deleteUser(data) {
      const payload = {
        comment: data?.comment,
      }
      this.$store.dispatch('manualProfileReview/setProfileFlag', { id: this.selectedData?.id, payload })
      this.$store.dispatch('user/delete', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The user has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
    patchProfile(payload, type) {
      const message = {
        flag: `${this.selectedFlag} tag has been ${this.isRemoveFlag ? 'removed' : 'added'}!`,
        profileStat: 'User has been deactived',
        profileSuspend: 'User has been suspended',
      }

      this.$store.dispatch('manualProfileReview/setProfileFlag', { id: this.selectedProfile?.id, payload })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: message[type],
              variant: 'success',
            },
          })

          this.setFlagModalVisible = false
          this.loadData()
        })
    },
    access() {
      return this.canAccess('manage', 'ManualProfileReview')
    },
    canManage() {
      return { callback: () => this.access }
    },
    canFlag(type) {
      return { callback: data => !data?.tags.includes(type) && this.access }
    },
    canRemoveFlag(type) {
      return { callback: data => data?.tags.includes(type) && this.access }
    },
    updateProfileFlag(val, flagType, remove) {
      this.isRemoveFlag = !!remove
      this.selectedProfile = {
        id: val?.item.id,
        tags: val?.item.tags || [],
      }
      this.selectedFlag = flagType
      this.setFlagModalVisible = true
    },
    setFlag(data) {
      let updatedTags = []

      if (this.isRemoveFlag) {
        updatedTags = this.selectedProfile.tags.filter(tag => tag !== this.selectedFlag)
      } else {
        updatedTags = [this.selectedFlag]
      }

      const payload = {
        comment: data?.comment,
        tags: updatedTags,
      }

      this.patchProfile(payload, 'flag')
    },
    suspendUser(data) {
      const payload = {
        comment: data?.comment,
        isSuspended: this.selectedData?.isSuspended !== 'Suspended',
      }

      this.$store.dispatch('manualProfileReview/setProfileFlag', { id: this.selectedData?.id, payload })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: `Success ${this.selectedData?.isSuspended === 'Suspended' ? 'Unsuspend' : 'Suspend'} User`,
              variant: 'success',
            },
          })

          this.suspendUserModalVisible = false
          this.loadData()
        })
    },
    confirmResetPassword(data) {
      this.selectedData = data.item
      this.resetPasswordModalVisible = true
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
    updateProfileSuspend(data) {
      this.selectedData = data?.item

      if (this.selectedData?.isSuspended === 'Suspended') {
        this.suspendUserModalTitle = 'Unsuspend'
      } else {
        this.suspendUserModalTitle = 'Suspend'
      }
      this.suspendUserModalVisible = true
    },
  },
}
</script>
