export const transactionFilterPayment = {
  key: 'paymentType',
  label: 'Payment method',
  type: 'payment',
  map: {
    paypal: 'Paypal',
    dragonpay_gcash: 'Gcash',
    stripe_paynow: 'Paynow',
    stripe_promptpay: 'Promptpay',
    gold: 'Gank Gold',
    stripe: 'Stripe',
    senangpay: 'Senangpay',
    xendit: 'Xendit',
    grabpay: 'Grabpay',
  },
  filter: {
    key: 'paymentTypes',
    type: 'multiple',
    options: [
      { value: 'gold', text: 'Gank Gold' },
      { value: 'dragonpay_gcash', text: 'Gcash' },
      { value: 'grabpay', text: 'Grabpay' },
      { value: 'paypal', text: 'Paypal' },
      { value: 'stripe_paynow', text: 'Paynow' },
      { value: 'stripe_promptpay', text: 'Promptpay' },
      { value: 'stripe', text: 'Stripe' },
      { value: 'senangpay', text: 'SenangPay' },
      { value: 'xendit', text: 'Xendit' },
    ],
  },
}

export const transactionFilterPaymentV2 = {
  key: 'payment_src',
  label: 'Payment method',
  type: 'badge',
  map: {
    CARD: 'card',
    GRABPAY: 'grabpay',
    STRIPE_GRABPAY: 'grabpay',
    IPAYMU: 'ipaymu',
    GCASH: 'gcash',
    WECHATPAY: 'wechatpay',
    EARNINGS: 'success',
    WALLET_GANK_EARNING: 'wallet_gank_earning',
    XENDIT_QRIS: 'xendit_qris',
    XENDIT_OVO: 'xendit_ovo',
    XENDIT_INTERNET_BANKING: 'xendit_internet_banking',
    XENDIT_DANA: 'xendit_dana',
    STRIPE: 'stripe',
    PAYPAL: 'paypal',
    STRIPE_PAYNOW: 'stripe_paynow',
    SENANGPAY: 'senangpay',
    DRAGONPAY_GCASH: 'dragonpay_gcash',
    STRIPE_PROMPTPAY: 'stripe_promptpay',
    XENDIT: 'xendit',
    GOPAY: 'gopay',
    GOOGLEPLAY: 'googleplay',
    GOLD: 'gold',
    WALLET_GANK_GOLD: 'gold',
    OMISE_LINEYPAY: 'linepay',
    OMISE_TRUEMONEY: 'truemoney',
    MIDTRANS: 'midtrans',
    ZALOPAY_WALLET: 'zalopay_wallet',
    ZALOPAY_VIETQR: 'zalopay_qr',
    ZALOPAY_LOCALBANK: 'zalopay_bank',
    SIAMPAY_QR_PROMTPAY: 'siampay_promptpay',
    SIAMPAY_TRUEMONEY: 'siampay_truemoney',
    happypay: 'happypay',
    STRIPE_GOOGLE_PLAY: 'stripe_google_play',
    STRIPE_APPLEPAY: 'stripe_applepay',
    ELEMENTPAY: 'elementpay',
    PEPAY_LINEPAY: 'pepay_linepay',
    PEPAY_JKO: 'pepay_jko',
    PEPAY_CONVENIENCE_STORE_FAMI: 'pepay_convenience_store_fami',
    PEPAY_CONVENIENCE_STORE_MBC: 'pepay_convenience_store_mbc',
    PEPAY_CONVENIENCE_STORE_IBON: 'pepay_convenience_store_ibon',
    PEPAY_ATM_CTCB: 'pepay_atm_ctcb',
    PEPAY_ATM_ESUN: 'pepay_atm_esun',
    PEPAY_ATM_HNCB: 'pepay_atm_hncb',
    PEPAY_ATM_SNSB: 'pepay_atm_snsb',
  },
  filter: {
    key: 'payment_srcs',
    type: 'select',
    options: [
      { value: 'STRIPE_PAYNOW', text: 'Stripe paynow' },
      { value: 'DRAGONPAY_GCASH', text: 'Dragonpay gcash' },
      { value: 'IPAYMU', text: 'Ipaymu' },
      { value: 'STRIPE_GUEST', text: 'Stripe guest' },
      { value: 'STRIPE_PROMPTPAY', text: 'Stripe promptpay' },
      { value: 'XENDIT_QRIS', text: 'QRIS' },
      { value: 'XENDIT_OVO', text: 'OVO' },
      { value: 'XENDIT_INTERNET_BANKING', text: 'Internet Banking' },
      { value: 'XENDIT_DANA', text: 'DANA' },
      { value: 'MIDTRANS', text: 'Midtrans' },
      { value: 'STRIPE', text: 'Stripe' },
      { value: 'INTERNAL', text: 'Internal' },
      { value: 'APPLE_STORE', text: 'Apple store' },
      { value: 'STRIPE_GRABPAY', text: 'Stripe grabpay' },
      { value: 'SENANGPAY', text: 'Senangpay' },
      { value: 'STRIPE_WECHAT_PAY', text: 'Stripe wechat pay' },
      { value: 'WALLET_GANK_EARNING', text: 'Wallet gank earning' },
      { value: 'GOOGLE_PLAY', text: 'Google play mobile' },
      { value: 'PAYPAL', text: 'Paypal' },
      { value: 'WALLET_GANK_GOLD', text: 'Wallet gank gold' },
      { value: 'ZALOPAY_WALLET', text: 'Zalopay Wallet' },
      { value: 'ZALOPAY_VIETQR', text: 'Zalopay QR' },
      { value: 'ZALOPAY_LOCALBANK', text: 'Zalopay Bank' },
      { value: 'SIAMPAY_QR_PROMTPAY', text: 'Siampay Promptpay' },
      { value: 'SIAMPAY_TRUEMONEY', text: 'Siampay Truemoney' },
      { value: 'HAPPYPAY', text: 'linepay' },
      { value: 'STRIPE_GOOGLE_PLAY', text: 'GooglePay' },
      { value: 'STRIPE_APPLEPAY', text: 'ApplePay' },
      { value: 'ELEMENTPAY', text: 'Promptpay New' },
      { value: 'PEPAY_LINEPAY', text: 'Linepay' },
      { value: 'PEPAY_JKO', text: 'JKO' },
      { value: 'PEPAY_CONVENIENCE_STORE_FAMI', text: 'Fami' },
      { value: 'PEPAY_CONVENIENCE_STORE_MBC', text: '7eleven barcode' },
      { value: 'PEPAY_CONVENIENCE_STORE_IBON', text: 'ibon' },
      { value: 'PEPAY_ATM_CTCB', text: 'CTCB' },
      { value: 'PEPAY_ATM_ESUN', text: 'ESUN' },
      { value: 'PEPAY_ATM_HNCB', text: 'HNCB' },
      { value: 'PEPAY_ATM_SNSB', text: 'SNSB' },
    ],
  },
}

export const transactionViewPayment = {
  key: 'paymentType',
  label: 'Payment method',
  type: 'payment',
  map: {
    paypal: 'Paypal',
    dragonpay_gcash: 'Gcash',
    stripe_paynow: 'Paynow',
    stripe_promptpay: 'Promptpay',
    gold: 'Gold',
    stripe: 'Stripe',
    senangpay: 'Senangpay',
    xendit: 'Xendit',
    grabpay: 'Grabpay',
  },
}

export const subscriptionFilterPayment = {
  key: 'paymentMethod',
  label: 'Payment method',
  type: 'payment',
  map: {
    paypal: 'Paypal',
    dragonpay_gcash: 'Gcash',
    stripe_paynow: 'Paynow',
    stripe_promptpay: 'Promptpay',
    gold: 'Gank Gold',
    stripe: 'Stripe',
    senangpay: 'Senangpay',
    xendit: 'Xendit',
    grabpay: 'Grabpay',
  },
  filter: {
    key: 'paymentMethods',
    type: 'multiple',
    options: [
      { value: 'gold', text: 'Gank Gold' },
      { value: 'dragonpay_gcash', text: 'Gcash' },
      { value: 'grabpay', text: 'Grabpay' },
      { value: 'paypal', text: 'Paypal' },
      { value: 'stripe_paynow', text: 'Paynow' },
      { value: 'stripe_promptpay', text: 'Promptpay' },
      { value: 'stripe', text: 'Stripe' },
      { value: 'senangpay', text: 'SenangPay' },
      { value: 'xendit', text: 'Xendit' },
    ],
  },
}

export const subscriptionViewPayment = {
  key: 'paymentMethod',
  label: 'Payment method',
  type: 'payment',
  map: {
    paypal: 'Paypal',
    dragonpay_gcash: 'Gcash',
    stripe_paynow: 'Paynow',
    stripe_promptpay: 'Promptpay',
    gold: 'Gold',
    stripe: 'Stripe',
    senangpay: 'Senangpay',
    xendit: 'Xendit',
    grabpay: 'Grabpay',
  },
}

export const withdrawalFilterPayment = {
  key: 'payment',
  label: 'Payment method',
  type: 'payment',
  sortable: false,
  map: {
    affin_bank: 'Affin Bank',
    alliance_bank: 'Alliance Bank',
    aub: 'Asia United Bank',
    ambank: 'AmBank',
    bank_islam: 'Bank Islam',
    bank_rakyat: 'Bank Rakyat',
    bbl: 'Bangkok Bank',
    bca: 'BCA',
    bdo: 'BDO Pay',
    bdon: 'BDO Network Bank',
    bfb: 'BPI Family Savings',
    bni: 'BNI',
    boc: 'Bank of Commerce',
    bpi: 'BPI',
    bri: 'BRI',
    bsn: 'BSN',
    ID_CIMB: 'Bank CIMB Niaga',
    ID_DANAMON: 'Bank Danamon',
    ID_PERMATA: 'Bank Permata',
    ID_PANIN: 'Bank Panin',
    ID_SINARMAS: 'Bank Sinarmas',
    ID_AGRONIAGA: 'Bank BRI Agroniaga',
    ID_ANZ: 'Bank ANZ Indonesia',
    ID_ARTHA: 'Bank Artha Graha Internasional',
    ID_BISNIS_INTERNASIONAL: 'Bank Bisnis Internasional',
    ID_BJB: 'Bank BJB',
    ID_BJB_SYR: 'Bank BJB Syariah',
    ID_BNP_PARIBAS: 'Bank BNP Paribas',
    ID_BUKOPIN: 'Bank Bukopin',
    ID_BUMI_ARTA: 'Bank Bumi Arta',
    ID_CAPITAL: 'Bank Capital Indonesia',
    ID_BCA_SYR: 'Bank Central Asia (BCA) Syariah',
    ID_BCA_DIGITAL: 'Bank Central Asia Digital (BluBCA)',
    ID_CHINATRUST: 'Bank Chinatrust Indonesia',
    ID_CIMB_UUS: 'Bank CIMB Niaga UUS',
    ID_COMMONWEALTH: 'Bank Commonwealth',
    ID_DANAMON_UUS: 'Bank Danamon UUS',
    ID_DBS: 'Bank DBS Indonesia',
    ID_DINAR_INDONESIA: 'Bank Dinar Indonesia',
    ID_DKI: 'Bank DKI',
    ID_DKI_UUS: 'Bank DKI UUS',
    ID_FAMA: 'Bank Fama International',
    ID_GANESHA: 'Bank Ganesha',
    ID_HANA: 'Bank Hana',
    ID_ICBC: 'Bank ICBC Indonesia',
    ID_INA_PERDANA: 'Bank Ina Perdania',
    ID_INDEX_SELINDO: 'Bank Index Selindo',
    ID_JASA_JAKARTA: 'Bank Jasa Jakarta',
    ID_MASPION: 'Bank Maspion Indonesia',
    ID_MAYAPADA: 'Bank Mayapada International',
    ID_MAYBANK_SYR: 'Bank Maybank Syariah Indonesia',
    ID_MAYORA: 'Bank Mayora',
    ID_MEGA: 'Bank Mega',
    ID_MESTIKA_DHARMA: 'Bank Mestika Dharma',
    ID_MIZUHO: 'Bank Mizuho Indonesia',
    ID_MNC_INTERNASIONAL: 'Bank MNC Internasional',
    ID_MUAMALAT: 'Bank Muamalat Indonesia',
    ID_MULTI_ARTA_SENTOSA: 'Bank Multi Arta Sentosa',
    ID_NATIONALNOBU: 'Bank Nationalnobu',
    ID_OCBC: 'Bank OCBC NISP',
    ID_OCBC_UUS: 'Bank OCBC NISP UUS',
    ID_BAML: 'Bank of America Merill-Lynch',
    ID_BOC: 'Bank of China (BOC)',
    ID_INDIA: 'Bank of India Indonesia',
    ID_TOKYO: 'Bank of Tokyo Mitsubishi UFJ',
    ID_PANIN_SYR: 'Bank Panin Syariah',
    ID_PERMATA_UUS: 'Bank Permata UUS',
    ID_RABOBANK: 'Bank Rabobank International Indonesia',
    ID_RESONA: 'Bank Resona Perdania',
    ID_SAHABAT_SAMPOERNA: 'Bank Sahabat Sampoerna',
    ID_SBI_INDONESIA: 'Bank SBI Indonesia',
    ID_BUKOPIN_SYR: 'Bank Syariah Bukopin',
    ID_MEGA_SYR: 'Bank Syariah Mega',
    ID_BTN: 'Bank Tabungan Negara (BTN)',
    ID_BTN_UUS: 'Bank Tabungan Negara (BTN) UUS',
    ID_TABUNGAN_PENSIUNAN_NASIONAL: 'Bank Tabungan Pensiunan Nasional',
    ID_UOB: 'Bank UOB Indonesia',
    ID_VICTORIA_INTERNASIONAL: 'Bank Victoria Internasional',
    ID_VICTORIA_SYR: 'Bank Victoria Syariah',
    ID_WOORI: 'Bank Woori Indonesia',
    ID_ACEH: 'BPD Aceh',
    ID_ACEH_UUS: 'BPD Aceh UUS',
    ID_BALI: 'BPD Bali',
    ID_BENGKULU: 'BPD Bengkulu',
    ID_DAERAH_ISTIMEWA: 'BPD Daerah Istimewa Yogyakarta (DIY)',
    ID_DAERAH_ISTIMEWA_UUS: 'BPD Daerah Istimewa Yogyakarta (DIY) UUS',
    ID_JAMBI: 'BPD Jambi',
    ID_JAMBI_UUS: 'BPD Jambi UUS',
    ID_JAWA_TENGAH: 'BPD Jawa Tengah',
    ID_JAWA_TENGAH_UUS: 'BPD Jawa Tengah UUS',
    ID_JAWA_TIMUR: 'BPD Jawa Timur',
    ID_JAWA_TIMUR_UUS: 'BPD Jawa Timur UUS',
    ID_KALIMANTAN_BARAT: 'BPD Kalimantan Barat',
    ID_KALIMANTAN_BARAT_UUS: 'BPD Kalimantan Barat UUS',
    ID_KALIMANTAN_SELATAN: 'BPD Kalimantan Selatan',
    ID_KALIMANTAN_SELATAN_UUS: 'BPD Kalimantan Selatan UUS',
    ID_KALIMANTAN_TENGAH: 'BPD Kalimantan Tengah',
    ID_KALIMANTAN_TIMUR: 'BPD Kalimantan Timur',
    ID_KALIMANTAN_TIMUR_UUS: 'BPD Kalimantan Timur UUS',
    ID_LAMPUNG: 'BPD Lampung',
    ID_MALUKU: 'BPD Maluku',
    ID_NUSA_TENGGARA_BARAT: 'BPD Nusa Tenggara Barat',
    ID_NUSA_TENGGARA_TIMUR: 'BPD Nusa Tenggara Timur',
    ID_PAPUA: 'BPD Papua',
    ID_RIAU_DAN_KEPRI: 'BPD Riau Dan Kepri',
    ID_SULAWESI: 'BPD Sulawesi Tengah',
    ID_SULAWESI_TENGGARA: 'BPD Sulawesi Tenggara',
    ID_SULSELBAR: 'BPD Sulselbar',
    ID_SULSELBAR_UUS: 'BPD Sulselbar UUS',
    ID_SULUT: 'BPD Sulut',
    ID_SUMATERA_BARAT: 'BPD Sumatera Barat',
    ID_SUMATERA_BARAT_UUS: 'BPD Sumatera Barat UUS',
    ID_SUMSEL_DAN_BABEL: 'BPD Sumsel Dan Babel',
    ID_SUMSEL_DAN_BABEL_UUS: 'BPD Sumsel Dan Babel UUS',
    ID_SUMUT: 'BPD Sumut',
    ID_SUMUT_UUS: 'BPD Sumut UUS',
    ID_CITIBANK: 'Citibank',
    ID_DEUTSCHE: 'Deutsche Bank',
    ID_HSBC: 'HSBC Indonesia',
    ID_JPMORGAN: 'JP Morgan Chase Bank',
    ID_PRIMA_MASTER: 'Prima Master Bank',
    ID_STANDARD_CHARTERED: 'Standard Chartered Bank',
    ID_MAYBANK: 'Bank Maybank',
    ID_SHINHAN: 'Bank Shinhan Indonesia',
    ID_MANDIRI_TASPEN: 'Mandiri Taspen Pos',
    ID_QNB_INDONESIA: 'Bank QNB Indonesia',
    ID_BANTEN: 'BPD Banten',
    ID_JTRUST: 'Bank JTrust Indonesia',
    ID_BTPN_SYARIAH: 'BTPN Syariah',
    ID_CCB: 'China Construction Bank Indonesia',
    ID_SINARMAS_UUS: 'Bank Sinarmas UUS',
    ID_AMAR: 'Bank Amar Indonesia',
    ID_OKE: 'Bank Oke Indonesia',
    ID_BSI: 'Bank Syariah Indonesia (BSI)',
    ID_ALADIN: 'Bank Aladin Syariah',
    ID_ALLO: 'Allo Bank Indonesia',
    ID_BNC: 'Bank Neo Commerce',
    ID_IBK: 'Bank IBK Indonesia',
    ID_JAGO: 'Bank Jago',
    ID_SEABANK: 'Bank Seabank Indonesia',
    ID_LINKAJA: 'Linkaja',
    ID_SHOPEEPAY: 'ShopeePay',
    cbcs: 'Chinabank Savings',
    chinabank: 'China Bank',
    cimb_bank: 'Cimb Bank',
    citibank: 'Citibank',
    ctbc: 'Chinatrust Bank',
    dana: 'Dana',
    dbp: 'Development Bank of Philippines',
    dbs: 'DBS / POSB',
    ewb: 'Eastwest Bank',
    gcash: 'GCash',
    gopay: 'Gopay',
    hong_leong_bank: 'Hong Leong Bank',
    hsbc_bank: 'HSBC Bank',
    kbank: 'Kasikorn Thai',
    krungsri: 'Krungsri Bank',
    krungthai: 'Krungthai Bank',
    lbp: 'Landbank',
    mandiri: 'Mandiri',
    may: 'Maybank PH',
    maybank: 'Maybank MY',
    metro: 'Metro',
    ocbc_bank: 'OCBC Bank',
    omise_promptpay: 'Promptpay',
    ovo: 'OVO',
    paymaya: 'Paymaya',
    paynow: 'Paynow',
    paypal: 'Paypal',
    pbcm: 'PBCom',
    pnb: 'Philippine National Bank',
    psb: 'PSBank',
    public_bank: 'Public Bank',
    pvb: 'Philippines Veteran Bank',
    rcbc: 'RCBC',
    rhb_bank: 'RHB Bank',
    rsb: 'Robinsons Bank',
    sba: 'Sterling Bank of Asia',
    sbc: 'Security Bank',
    scb: 'Siam Commercial Bank',
    standard_chartered_bank: 'Standard Chartered Bank',
    stripe: 'Stripe',
    ucpb: 'UCPB',
    touch_n_go: 'Touch \'n Go',
    union: 'Union',
    uob: 'UOB',
    wise: 'Wise',
    wise_gbp: 'Wise GBP',
    zalopay: 'Zalopay',
    manual: 'Other Bank',
  },
  filter: {
    key: 'payments',
    type: 'multiple',
    options: [
      { value: 'affin_bank', text: 'Affin Bank' },
      { value: 'alliance_bank', text: 'Alliance Bank' },
      { value: 'ambank', text: 'AmBank' },
      { value: 'aub', text: 'Asia United Bank' },
      { value: 'bank_islam', text: 'Bank Islam' },
      { value: 'bank_rakyat', text: 'Bank Rakyat' },
      { value: 'bbl', text: 'Bangkok Bank' },
      { value: 'bca', text: 'BCA' },
      { value: 'bdon', text: 'BDO Network Bank' },
      { value: 'bdo', text: 'BDO Pay' },
      { value: 'bfb', text: 'BPI Family Savings' },
      { value: 'bni', text: 'BNI' },
      { value: 'boc', text: 'Bank of Commerce' },
      { value: 'bpi', text: 'BPI' },
      { value: 'bri', text: 'BRI' },
      { value: 'bsn', text: 'BSN' },
      { value: 'cbcs', text: 'Chinabank Savings' },
      { value: 'chinabank', text: 'China Bank' },
      { value: 'cimb_bank', text: 'Cimb Bank' },
      { value: 'citibank', text: 'Citibank' },
      { value: 'ctbc', text: 'Chinatrust Bank' },
      { value: 'dana', text: 'Dana' },
      { value: 'dbp', text: 'Development Bank of Philippines' },
      { value: 'dbs', text: 'DBS / POSB' },
      { value: 'ewb', text: 'Eastwest Bank' },
      { value: 'gcash', text: 'GCash' },
      { value: 'gopay', text: 'Gopay' },
      { value: 'hong_leong_bank', text: 'Hong Leong Bank' },
      { value: 'hsbc_bank', text: 'HSBC Bank' },
      { value: 'kbank', text: 'Kasikorn Thai' },
      { value: 'krungsri', text: 'Krungsri Bank' },
      { value: 'krungthai', text: 'Krungthai Bank' },
      { value: 'lbp', text: 'Landbank' },
      { value: 'mandiri', text: 'Mandiri' },
      { value: 'manual', text: 'Other Bank' },
      { value: 'may', text: 'Maybank PH' },
      { value: 'maybank', text: 'Maybank MY' },
      { value: 'metro', text: 'Metro' },
      { value: 'ocbc_bank', text: 'OCBC Bank' },
      { value: 'omise_promptpay', text: 'Promptpay' },
      { value: 'ovo', text: 'OVO' },
      { value: 'paymaya', text: 'Paymaya' },
      { value: 'paynow', text: 'Paynow' },
      { value: 'paypal', text: 'Paypal' },
      { value: 'pbcm', text: 'PBCom' },
      { value: 'pnb', text: 'Philippine National Bank' },
      { value: 'psb', text: 'PSBank' },
      { value: 'public_bank', text: 'Public Bank' },
      { value: 'pvb', text: 'Philippines Veteran Bank' },
      { value: 'rcbc', text: 'RCBC' },
      { value: 'rhb_bank', text: 'RHB Bank' },
      { value: 'rsb', text: 'Robinsons Bank' },
      { value: 'sba', text: 'Sterling Bank of Asia' },
      { value: 'sbc', text: 'Security Bank' },
      { value: 'scb', text: 'Siam Commercial Bank' },
      { value: 'standard_chartered_bank', text: 'Standard Chartered Bank' },
      { value: 'stripe', text: 'Stripe' },
      { value: 'touch_n_go', text: 'Touch \'n Go' },
      { value: 'ucpb', text: 'UCPB' },
      { value: 'union', text: 'Union' },
      { value: 'uob', text: 'UOB' },
      { value: 'wise', text: 'Wise' },
      { value: 'wise_gbp', text: 'Wise GBP' },
      { value: 'zalopay', text: 'Zalopay' },
      { value: 'ID_CIMB', text: 'Bank CIMB Niaga' },
      { value: 'ID_DANAMON', text: 'Bank Danamon' },
      { value: 'ID_PERMATA', text: 'Bank Permata' },
      { value: 'ID_PANIN', text: 'Bank Panin' },
      { value: 'ID_SINARMAS', text: 'Bank Sinarmas' },
      { value: 'ID_AGRONIAGA', text: 'Bank BRI Agroniaga' },
      { value: 'ID_ANZ', text: 'Bank ANZ Indonesia' },
      { value: 'ID_ARTHA', text: 'Bank Artha Graha Internasional' },
      { value: 'ID_BISNIS_INTERNASIONAL', text: 'Bank Bisnis Internasional' },
      { value: 'ID_BJB', text: 'Bank BJB' },
      { value: 'ID_BJB_SYR', text: 'Bank BJB Syariah' },
      { value: 'ID_BNP_PARIBAS', text: 'Bank BNP Paribas' },
      { value: 'ID_BUKOPIN', text: 'Bank Bukopin' },
      { value: 'ID_BUMI_ARTA', text: 'Bank Bumi Arta' },
      { value: 'ID_CAPITAL', text: 'Bank Capital Indonesia' },
      { value: 'ID_BCA_SYR', text: 'Bank Central Asia (BCA) Syariah' },
      { value: 'ID_BCA_DIGITAL', text: 'Bank Central Asia Digital (BluBCA)' },
      { value: 'ID_CHINATRUST', text: 'Bank Chinatrust Indonesia' },
      { value: 'ID_CIMB_UUS', text: 'Bank CIMB Niaga UUS' },
      { value: 'ID_COMMONWEALTH', text: 'Bank Commonwealth' },
      { value: 'ID_DANAMON_UUS', text: 'Bank Danamon UUS' },
      { value: 'ID_DBS', text: 'Bank DBS Indonesia' },
      { value: 'ID_DINAR_INDONESIA', text: 'Bank Dinar Indonesia' },
      { value: 'ID_DKI', text: 'Bank DKI' },
      { value: 'ID_DKI_UUS', text: 'Bank DKI UUS' },
      { value: 'ID_FAMA', text: 'Bank Fama International' },
      { value: 'ID_GANESHA', text: 'Bank Ganesha' },
      { value: 'ID_HANA', text: 'Bank Hana' },
      { value: 'ID_ICBC', text: 'Bank ICBC Indonesia' },
      { value: 'ID_INA_PERDANA', text: 'Bank Ina Perdania' },
      { value: 'ID_INDEX_SELINDO', text: 'Bank Index Selindo' },
      { value: 'ID_JASA_JAKARTA', text: 'Bank Jasa Jakarta' },
      { value: 'ID_MASPION', text: 'Bank Maspion Indonesia' },
      { value: 'ID_MAYAPADA', text: 'Bank Mayapada International' },
      { value: 'ID_MAYBANK_SYR', text: 'Bank Maybank Syariah Indonesia' },
      { value: 'ID_MAYORA', text: 'Bank Mayora' },
      { value: 'ID_MEGA', text: 'Bank Mega' },
      { value: 'ID_MESTIKA_DHARMA', text: 'Bank Mestika Dharma' },
      { value: 'ID_MIZUHO', text: 'Bank Mizuho Indonesia' },
      { value: 'ID_MNC_INTERNASIONAL', text: 'Bank MNC Internasional' },
      { value: 'ID_MUAMALAT', text: 'Bank Muamalat Indonesia' },
      { value: 'ID_MULTI_ARTA_SENTOSA', text: 'Bank Multi Arta Sentosa' },
      { value: 'ID_NATIONALNOBU', text: 'Bank Nationalnobu' },
      { value: 'ID_OCBC', text: 'Bank OCBC NISP' },
      { value: 'ID_OCBC_UUS', text: 'Bank OCBC NISP UUS' },
      { value: 'ID_BAML', text: 'Bank of America Merill-Lynch' },
      { value: 'ID_BOC', text: 'Bank of China (BOC)' },
      { value: 'ID_INDIA', text: 'Bank of India Indonesia' },
      { value: 'ID_TOKYO', text: 'Bank of Tokyo Mitsubishi UFJ' },
      { value: 'ID_PANIN_SYR', text: 'Bank Panin Syariah' },
      { value: 'ID_PERMATA_UUS', text: 'Bank Permata UUS' },
      { value: 'ID_RABOBANK', text: 'Bank Rabobank International Indonesia' },
      { value: 'ID_RESONA', text: 'Bank Resona Perdania' },
      { value: 'ID_SAHABAT_SAMPOERNA', text: 'Bank Sahabat Sampoerna' },
      { value: 'ID_SBI_INDONESIA', text: 'Bank SBI Indonesia' },
      { value: 'ID_BUKOPIN_SYR', text: 'Bank Syariah Bukopin' },
      { value: 'ID_MEGA_SYR', text: 'Bank Syariah Mega' },
      { value: 'ID_BTN', text: 'Bank Tabungan Negara (BTN)' },
      { value: 'ID_BTN_UUS', text: 'Bank Tabungan Negara (BTN) UUS' },
      { value: 'ID_TABUNGAN_PENSIUNAN_NASIONAL', text: 'Bank Tabungan Pensiunan Nasional' },
      { value: 'ID_UOB', text: 'Bank UOB Indonesia' },
      { value: 'ID_VICTORIA_INTERNASIONAL', text: 'Bank Victoria Internasional' },
      { value: 'ID_VICTORIA_SYR', text: 'Bank Victoria Syariah' },
      { value: 'ID_WOORI', text: 'Bank Woori Indonesia' },
      { value: 'ID_ACEH', text: 'BPD Aceh' },
      { value: 'ID_ACEH_UUS', text: 'BPD Aceh UUS' },
      { value: 'ID_BALI', text: 'BPD Bali' },
      { value: 'ID_BENGKULU', text: 'BPD Bengkulu' },
      { value: 'ID_DAERAH_ISTIMEWA', text: 'BPD Daerah Istimewa Yogyakarta (DIY)' },
      { value: 'ID_DAERAH_ISTIMEWA_UUS', text: 'BPD Daerah Istimewa Yogyakarta (DIY) UUS' },
      { value: 'ID_JAMBI', text: 'BPD Jambi' },
      { value: 'ID_JAMBI_UUS', text: 'BPD Jambi UUS' },
      { value: 'ID_JAWA_TENGAH', text: 'BPD Jawa Tengah' },
      { value: 'ID_JAWA_TENGAH_UUS', text: 'BPD Jawa Tengah UUS' },
      { value: 'ID_JAWA_TIMUR', text: 'BPD Jawa Timur' },
      { value: 'ID_JAWA_TIMUR_UUS', text: 'BPD Jawa Timur UUS' },
      { value: 'ID_KALIMANTAN_BARAT', text: 'BPD Kalimantan Barat' },
      { value: 'ID_KALIMANTAN_BARAT_UUS', text: 'BPD Kalimantan Barat UUS' },
      { value: 'ID_KALIMANTAN_SELATAN', text: 'BPD Kalimantan Selatan' },
      { value: 'ID_KALIMANTAN_SELATAN_UUS', text: 'BPD Kalimantan Selatan UUS' },
      { value: 'ID_KALIMANTAN_TENGAH', text: 'BPD Kalimantan Tengah' },
      { value: 'ID_KALIMANTAN_TIMUR', text: 'BPD Kalimantan Timur' },
      { value: 'ID_KALIMANTAN_TIMUR_UUS', text: 'BPD Kalimantan Timur UUS' },
      { value: 'ID_LAMPUNG', text: 'BPD Lampung' },
      { value: 'ID_MALUKU', text: 'BPD Maluku' },
      { value: 'ID_NUSA_TENGGARA_BARAT', text: 'BPD Nusa Tenggara Barat' },
      { value: 'ID_NUSA_TENGGARA_TIMUR', text: 'BPD Nusa Tenggara Timur' },
      { value: 'ID_PAPUA', text: 'BPD Papua' },
      { value: 'ID_RIAU_DAN_KEPRI', text: 'BPD Riau Dan Kepri' },
      { value: 'ID_SULAWESI', text: 'BPD Sulawesi Tengah' },
      { value: 'ID_SULAWESI_TENGGARA', text: 'BPD Sulawesi Tenggara' },
      { value: 'ID_SULSELBAR', text: 'BPD Sulselbar' },
      { value: 'ID_SULSELBAR_UUS', text: 'BPD Sulselbar UUS' },
      { value: 'ID_SULUT', text: 'BPD Sulut' },
      { value: 'ID_SUMATERA_BARAT', text: 'BPD Sumatera Barat' },
      { value: 'ID_SUMATERA_BARAT_UUS', text: 'BPD Sumatera Barat UUS' },
      { value: 'ID_SUMSEL_DAN_BABEL', text: 'BPD Sumsel Dan Babel' },
      { value: 'ID_SUMSEL_DAN_BABEL_UUS', text: 'BPD Sumsel Dan Babel UUS' },
      { value: 'ID_SUMUT', text: 'BPD Sumut' },
      { value: 'ID_SUMUT_UUS', text: 'BPD Sumut UUS' },
      { value: 'ID_CITIBANK', text: 'Citibank' },
      { value: 'ID_DEUTSCHE', text: 'Deutsche Bank' },
      { value: 'ID_HSBC', text: 'HSBC Indonesia' },
      { value: 'ID_JPMORGAN', text: 'JP Morgan Chase Bank' },
      { value: 'ID_PRIMA_MASTER', text: 'Prima Master Bank' },
      { value: 'ID_STANDARD_CHARTERED', text: 'Standard Chartered Bank' },
      { value: 'ID_MAYBANK', text: 'Bank Maybank' },
      { value: 'ID_SHINHAN', text: 'Bank Shinhan Indonesia' },
      { value: 'ID_MANDIRI_TASPEN', text: 'Mandiri Taspen Pos' },
      { value: 'ID_QNB_INDONESIA', text: 'Bank QNB Indonesia' },
      { value: 'ID_BANTEN', text: 'BPD Banten' },
      { value: 'ID_JTRUST', text: 'Bank JTrust Indonesia' },
      { value: 'ID_BTPN_SYARIAH', text: 'BTPN Syariah' },
      { value: 'ID_CCB', text: 'China Construction Bank Indonesia' },
      { value: 'ID_SINARMAS_UUS', text: 'Bank Sinarmas UUS' },
      { value: 'ID_AMAR', text: 'Bank Amar Indonesia' },
      { value: 'ID_OKE', text: 'Bank Oke Indonesia' },
      { value: 'ID_BSI', text: 'Bank Syariah Indonesia (BSI)' },
      { value: 'ID_ALADIN', text: 'Bank Aladin Syariah' },
      { value: 'ID_ALLO', text: 'Allo Bank Indonesia' },
      { value: 'ID_BNC', text: 'Bank Neo Commerce' },
      { value: 'ID_IBK', text: 'Bank IBK Indonesia' },
      { value: 'ID_JAGO', text: 'Bank Jago' },
      { value: 'ID_SEABANK', text: 'Bank Seabank Indonesia' },
      { value: 'ID_LINKAJA', text: 'Linkaja' },
      { value: 'ID_SHOPEEPAY', text: 'ShopeePay' },
    ],
  },
}

export const withdrawalViewPayment = {
  key: 'payment',
  label: 'Payment method',
  type: 'payment',
  map: {
    affin_bank: 'Affin Bank',
    alliance_bank: 'Alliance Bank',
    aub: 'Asia United Bank',
    ambank: 'AmBank',
    bank_islam: 'Bank Islam',
    bank_rakyat: 'Bank Rakyat',
    bbl: 'Bangkok Bank',
    bca: 'BCA',
    bdo: 'BDO Pay',
    bdon: 'BDO Network Bank',
    bfb: 'BPI Family Savings',
    bni: 'BNI',
    boc: 'Bank of Commerce',
    bpi: 'BPI',
    bri: 'BRI',
    bsn: 'BSN',
    ID_CIMB: 'Bank CIMB Niaga',
    ID_DANAMON: 'Bank Danamon',
    ID_PERMATA: 'Bank Permata',
    ID_PANIN: 'Bank Panin',
    ID_SINARMAS: 'Bank Sinarmas',
    ID_AGRONIAGA: 'Bank BRI Agroniaga',
    ID_ANZ: 'Bank ANZ Indonesia',
    ID_ARTHA: 'Bank Artha Graha Internasional',
    ID_BISNIS_INTERNASIONAL: 'Bank Bisnis Internasional',
    ID_BJB: 'Bank BJB',
    ID_BJB_SYR: 'Bank BJB Syariah',
    ID_BNP_PARIBAS: 'Bank BNP Paribas',
    ID_BUKOPIN: 'Bank Bukopin',
    ID_BUMI_ARTA: 'Bank Bumi Arta',
    ID_CAPITAL: 'Bank Capital Indonesia',
    ID_BCA_SYR: 'Bank Central Asia (BCA) Syariah',
    ID_BCA_DIGITAL: 'Bank Central Asia Digital (BluBCA)',
    ID_CHINATRUST: 'Bank Chinatrust Indonesia',
    ID_CIMB_UUS: 'Bank CIMB Niaga UUS',
    ID_COMMONWEALTH: 'Bank Commonwealth',
    ID_DANAMON_UUS: 'Bank Danamon UUS',
    ID_DBS: 'Bank DBS Indonesia',
    ID_DINAR_INDONESIA: 'Bank Dinar Indonesia',
    ID_DKI: 'Bank DKI',
    ID_DKI_UUS: 'Bank DKI UUS',
    ID_FAMA: 'Bank Fama International',
    ID_GANESHA: 'Bank Ganesha',
    ID_HANA: 'Bank Hana',
    ID_ICBC: 'Bank ICBC Indonesia',
    ID_INA_PERDANA: 'Bank Ina Perdania',
    ID_INDEX_SELINDO: 'Bank Index Selindo',
    ID_JASA_JAKARTA: 'Bank Jasa Jakarta',
    ID_MASPION: 'Bank Maspion Indonesia',
    ID_MAYAPADA: 'Bank Mayapada International',
    ID_MAYBANK_SYR: 'Bank Maybank Syariah Indonesia',
    ID_MAYORA: 'Bank Mayora',
    ID_MEGA: 'Bank Mega',
    ID_MESTIKA_DHARMA: 'Bank Mestika Dharma',
    ID_MIZUHO: 'Bank Mizuho Indonesia',
    ID_MNC_INTERNASIONAL: 'Bank MNC Internasional',
    ID_MUAMALAT: 'Bank Muamalat Indonesia',
    ID_MULTI_ARTA_SENTOSA: 'Bank Multi Arta Sentosa',
    ID_NATIONALNOBU: 'Bank Nationalnobu',
    ID_OCBC: 'Bank OCBC NISP',
    ID_OCBC_UUS: 'Bank OCBC NISP UUS',
    ID_BAML: 'Bank of America Merill-Lynch',
    ID_BOC: 'Bank of China (BOC)',
    ID_INDIA: 'Bank of India Indonesia',
    ID_TOKYO: 'Bank of Tokyo Mitsubishi UFJ',
    ID_PANIN_SYR: 'Bank Panin Syariah',
    ID_PERMATA_UUS: 'Bank Permata UUS',
    ID_RABOBANK: 'Bank Rabobank International Indonesia',
    ID_RESONA: 'Bank Resona Perdania',
    ID_SAHABAT_SAMPOERNA: 'Bank Sahabat Sampoerna',
    ID_SBI_INDONESIA: 'Bank SBI Indonesia',
    ID_BUKOPIN_SYR: 'Bank Syariah Bukopin',
    ID_MEGA_SYR: 'Bank Syariah Mega',
    ID_BTN: 'Bank Tabungan Negara (BTN)',
    ID_BTN_UUS: 'Bank Tabungan Negara (BTN) UUS',
    ID_TABUNGAN_PENSIUNAN_NASIONAL: 'Bank Tabungan Pensiunan Nasional',
    ID_UOB: 'Bank UOB Indonesia',
    ID_VICTORIA_INTERNASIONAL: 'Bank Victoria Internasional',
    ID_VICTORIA_SYR: 'Bank Victoria Syariah',
    ID_WOORI: 'Bank Woori Indonesia',
    ID_ACEH: 'BPD Aceh',
    ID_ACEH_UUS: 'BPD Aceh UUS',
    ID_BALI: 'BPD Bali',
    ID_BENGKULU: 'BPD Bengkulu',
    ID_DAERAH_ISTIMEWA: 'BPD Daerah Istimewa Yogyakarta (DIY)',
    ID_DAERAH_ISTIMEWA_UUS: 'BPD Daerah Istimewa Yogyakarta (DIY) UUS',
    ID_JAMBI: 'BPD Jambi',
    ID_JAMBI_UUS: 'BPD Jambi UUS',
    ID_JAWA_TENGAH: 'BPD Jawa Tengah',
    ID_JAWA_TENGAH_UUS: 'BPD Jawa Tengah UUS',
    ID_JAWA_TIMUR: 'BPD Jawa Timur',
    ID_JAWA_TIMUR_UUS: 'BPD Jawa Timur UUS',
    ID_KALIMANTAN_BARAT: 'BPD Kalimantan Barat',
    ID_KALIMANTAN_BARAT_UUS: 'BPD Kalimantan Barat UUS',
    ID_KALIMANTAN_SELATAN: 'BPD Kalimantan Selatan',
    ID_KALIMANTAN_SELATAN_UUS: 'BPD Kalimantan Selatan UUS',
    ID_KALIMANTAN_TENGAH: 'BPD Kalimantan Tengah',
    ID_KALIMANTAN_TIMUR: 'BPD Kalimantan Timur',
    ID_KALIMANTAN_TIMUR_UUS: 'BPD Kalimantan Timur UUS',
    ID_LAMPUNG: 'BPD Lampung',
    ID_MALUKU: 'BPD Maluku',
    ID_NUSA_TENGGARA_BARAT: 'BPD Nusa Tenggara Barat',
    ID_NUSA_TENGGARA_TIMUR: 'BPD Nusa Tenggara Timur',
    ID_PAPUA: 'BPD Papua',
    ID_RIAU_DAN_KEPRI: 'BPD Riau Dan Kepri',
    ID_SULAWESI: 'BPD Sulawesi Tengah',
    ID_SULAWESI_TENGGARA: 'BPD Sulawesi Tenggara',
    ID_SULSELBAR: 'BPD Sulselbar',
    ID_SULSELBAR_UUS: 'BPD Sulselbar UUS',
    ID_SULUT: 'BPD Sulut',
    ID_SUMATERA_BARAT: 'BPD Sumatera Barat',
    ID_SUMATERA_BARAT_UUS: 'BPD Sumatera Barat UUS',
    ID_SUMSEL_DAN_BABEL: 'BPD Sumsel Dan Babel',
    ID_SUMSEL_DAN_BABEL_UUS: 'BPD Sumsel Dan Babel UUS',
    ID_SUMUT: 'BPD Sumut',
    ID_SUMUT_UUS: 'BPD Sumut UUS',
    ID_CITIBANK: 'Citibank',
    ID_DEUTSCHE: 'Deutsche Bank',
    ID_HSBC: 'HSBC Indonesia',
    ID_JPMORGAN: 'JP Morgan Chase Bank',
    ID_PRIMA_MASTER: 'Prima Master Bank',
    ID_STANDARD_CHARTERED: 'Standard Chartered Bank',
    ID_MAYBANK: 'Bank Maybank',
    ID_SHINHAN: 'Bank Shinhan Indonesia',
    ID_MANDIRI_TASPEN: 'Mandiri Taspen Pos',
    ID_QNB_INDONESIA: 'Bank QNB Indonesia',
    ID_BANTEN: 'BPD Banten',
    ID_JTRUST: 'Bank JTrust Indonesia',
    ID_BTPN_SYARIAH: 'BTPN Syariah',
    ID_CCB: 'China Construction Bank Indonesia',
    ID_SINARMAS_UUS: 'Bank Sinarmas UUS',
    ID_AMAR: 'Bank Amar Indonesia',
    ID_OKE: 'Bank Oke Indonesia',
    ID_BSI: 'Bank Syariah Indonesia (BSI)',
    ID_ALADIN: 'Bank Aladin Syariah',
    ID_ALLO: 'Allo Bank Indonesia',
    ID_BNC: 'Bank Neo Commerce',
    ID_IBK: 'Bank IBK Indonesia',
    ID_JAGO: 'Bank Jago',
    ID_SEABANK: 'Bank Seabank Indonesia',
    ID_LINKAJA: 'Linkaja',
    ID_SHOPEEPAY: 'ShopeePay',
    cbcs: 'Chinabank Savings',
    chinabank: 'China Bank',
    cimb_bank: 'Cimb Bank',
    citibank: 'Citibank',
    ctbc: 'Chinatrust Bank',
    dana: 'Dana',
    dbp: 'Development Bank of Philippines',
    dbs: 'DBS / POSB',
    ewb: 'Eastwest Bank',
    gcash: 'GCash',
    gopay: 'Gopay',
    hong_leong_bank: 'Hong Leong Bank',
    hsbc_bank: 'HSBC Bank',
    kbank: 'Kasikorn Thai',
    krungsri: 'Krungsri Bank',
    krungthai: 'Krungthai Bank',
    lbp: 'Landbank',
    mandiri: 'Mandiri',
    may: 'Maybank PH',
    maybank: 'Maybank MY',
    metro: 'Metro',
    ocbc_bank: 'OCBC Bank',
    omise_promptpay: 'Promptpay',
    ovo: 'OVO',
    paymaya: 'Paymaya',
    paynow: 'Paynow',
    paypal: 'Paypal',
    pbcm: 'PBCom',
    pnb: 'Philippine National Bank',
    psb: 'PSBank',
    public_bank: 'Public Bank',
    pvb: 'Philippines Veteran Bank',
    rcbc: 'RCBC',
    rhb_bank: 'RHB Bank',
    rsb: 'Robinsons Bank',
    sba: 'Sterling Bank of Asia',
    sbc: 'Security Bank',
    scb: 'Siam Commercial Bank',
    standard_chartered_bank: 'Standard Chartered Bank',
    ucpb: 'UCPB',
    touch_n_go: 'Touch \'n Go',
    union: 'Union',
    uob: 'UOB',
    zalopay: 'Zalopay',
    manual: 'Other Bank',
  },
}
